import React, { useContext, useState } from "react";
import "../dashboard.css";
import Footer from "../../footer/footer";

import { EXHIBITOR } from "../../../constants/constants";
import { EXHIBITOR_MENU_LIST, deleteUser } from "../../../utility/utility";
import Dashboard from "./Dashboard";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import Settings from "./Settings";
import Services from "./oes/Services";
import Onsite from "./Onsite";
import Reports from "./Reports";
import ProfileDetails from "./profile/ProfileDetails";
import NewDashHeader from "../header/NewDashHeader";
import SideMenuList from "./profile/SideMenuList";
import MuPb from "../../../widgets/MuPb";
import { Alert, Box, Stack } from "@mui/material";
import ExhibitionCatalogue from "./oes/ExhibitionCatalogue";
import MySchedule from "./MySchedule";
import MobileSideMenuList from "./profile/MobileSideMenuList";
import GeneralView from "../../floorplan/view/GeneralView";
import HelpdeskPopup from "./HelpdeskPopup";
import AllMeetings from "./meeting/AllMeetings";
import ProfileViewByID from "./profile/ProfileViewByID";
import ChatApp from "../../chatApp/ChatApp";
import MyFavorites from "../favorites/MyFavorites";
import ScheduleMeeting from "./meeting/ScheduleMeeting";
import ProductContainer from "./ProductContainer";
import ProfileViewers from "../favorites/ProfileViewers";
import MyStallViewers from "../favorites/MyStallViewers";
import MyChatList from "../common/MyChatList";
import NotificationsList from "./NotificationsList";
import { UserContext } from "../../context/UserContext";
import OesForms from "./OesForms";
import SupportRequest from "../../../pages/SupportRequest";
const ExhibitorDashboard = () => {
  const navigate = useNavigate();
  const { page } = useParams();
  const [openHelpDesk, setOpenHelpDesk] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);
  if (page === "exhibitor-services") {
    navigate("/exhibitor-services/0");
  }
  const { user, loading } = useContext(UserContext);

  const onLogout = () => {
    deleteUser();
    navigate("/login");
  };
  if (loading) {
    return <MuPb />;
  }
  if (!user) {
    return <Alert severity="error">No user data found!</Alert>;
  }

  return (
    <div className="rd-expo-dash-c">
      <HelpdeskPopup open={openHelpDesk} setOpen={setOpenHelpDesk} />
      <NewDashHeader />
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Stack direction={"row"}>
          <Box width={200}>
            <SideMenuList
              onLogout={onLogout}
              menuList={EXHIBITOR_MENU_LIST}
              page={page}
            />
            <MobileSideMenuList
              showSideMenu={showSideMenu}
              setShowSideMenu={setShowSideMenu}
              onLogout={onLogout}
              category={EXHIBITOR}
              menuList={EXHIBITOR_MENU_LIST}
              page={page}
            />
          </Box>

          <Box flex={1}>
            <Routes>
              <Route path="" element={<Dashboard />} />
              <Route path="profile" element={<ProfileDetails />} />
              <Route path="floor-plan" element={<GeneralView />} />
              <Route
                path="exhibition-catalogue"
                element={<ExhibitionCatalogue />}
              />
              <Route path="all-meetings" element={<AllMeetings />} />
              <Route path="exhibitor-services" element={<div />} />
              <Route path="services" element={<Services />} />
              <Route path="oes-forms" element={<OesForms />} />
              <Route path="my-schedule" element={<MySchedule />} />
              <Route path="support-request" element={<SupportRequest />} />
              <Route path="favorites" element={<MyFavorites />} />
              <Route path="settings" element={<Settings />} />
              <Route path="onsite" element={<Onsite />} />
              <Route path="reports" element={<Reports />} />
              <Route path="view-profile" element={<ProfileViewByID />} />
              <Route path="product-view" element={<ProductContainer />} />
              <Route path="profile-viewers" element={<ProfileViewers />} />
              <Route path="stall-viewers" element={<MyStallViewers />} />
              <Route path="chat" element={<ChatApp />} />
              <Route path="meeting-scheduler" element={<ScheduleMeeting />} />
              <Route path="my-chats" element={<MyChatList />} />
              <Route path="my-notifications" element={<NotificationsList />} />
            </Routes>
          </Box>
        </Stack>
        <Footer />
      </Box>
    </div>
  );
};

export default ExhibitorDashboard;
