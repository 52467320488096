import React, { useContext, useState } from "react";
import "../dashboard.css";
import InfoCard from "../../../widgets/InfoCard";
import pvsIcon from "../../../assets/views.png";
import meetingIcon from "../../../assets/meetings.png";
import stallsIcon from "../../../assets/stalls.png";
import { exhibitorFilledPercentage, getUser } from "../../../utility/utility";
import connectionsIcon from "../../../assets/connections.png";
import { CircularProgressbar } from "react-circular-progressbar";
import DashButton from "../../../widgets/DashButton";
import bgBanner from "../../../assets/db_gif.gif";
import { Link, useNavigate } from "react-router-dom";
import useTeamMembers from "./hooks/useTeamMembers";
import {
  Avatar,
  AvatarGroup,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import MeetingCard from "../../../widgets/MeetingCard";
import useMeetings from "./hooks/useMeetings";
import { SimilarExhibitors } from "./SimilarExhibitors";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import { API_ENDPOINT, USER } from "../../../constants/constants";
import useApiEndpoint from "../../events/hooks/useApiEndpoint";
import { UserContext } from "../../context/UserContext";
const Dashboard = () => {
  const { user, loading } = useContext(UserContext);
  const {
    data: viewCount,
    isLoading: isViewCountLoading,
    isError: isViewCountError,
    error: viewCountError,
  } = useCommonGetInit(`${USER}/my-views-count?`);
  const {
    data: stallViewCount,
    isLoading: isStallViewCountLoading,
    isError: isStallViewCountError,
    error: stallViewCountError,
  } = useApiEndpoint(`${API_ENDPOINT}user/stall-views-count?`);
  const {
    data: meetingCount,
    isLoading: isMeetingCountLoading,
    isError: isMeetingCountError,
    error: meetingCountError,
  } = useCommonGetInit(`${USER}/get-meeting-count?`);

  const {
    data: meetings,
    isLoading: isMeetingsLoading,
    isError: isMeetingsError,
    error: meetingsError,
  } = useCommonGetInit(`${USER}/get-my-meetings?`);

  const { data: teamMembers } = useTeamMembers(1);

  return (
    <Box>
      <div className="rd-dash-content">
        <div className="dash-cards-65-35">
          <div
            className="dash-card-65"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="left-flex-right">
              <div
                className="left-item-flex"
                style={{
                  backgroundImage: `url(${bgBanner})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    color: "white",
                    fontWeight: 500,
                    fontSize: "19px",
                  }}
                >
                  Welcome!
                </div>
                <div
                  style={{
                    color: "white",
                    fontSize: "17px",
                    marginTop: "4px",
                    fontWeight: 600,
                  }}
                >
                  {user?.companyName?.value}
                </div>
                <div
                  style={{
                    color: "white",
                    fontSize: "14px",
                    marginTop: "10px",
                  }}
                >
                  Track your profile activity, leads and deals here.
                </div>
              </div>
              <div className="right-item">
                <div
                  style={{
                    width: 90,
                    height: 90,
                    display: "block",
                    margin: "auto",
                  }}
                >
                  <CircularProgressbar
                    strokeWidth={12}
                    value={24}
                    text={"24%"}
                  />
                </div>
                <div
                  style={{
                    color: "#666",
                    margin: "10px 12px",
                    fontWeight: 600,
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Complete your profile
                </div>
                <div style={{ flex: 1 }}></div>
                <Link
                  to={`/exhibitor/profile`}
                  style={{ textDecoration: "none" }}
                >
                  <DashButton
                    label="Update Profile"
                    color="rgb(47 146 188)"
                    hoverColor="rgb(21 80 106)"
                    bgColor="rgb(229 241 253)"
                    hoverBgColor="rgb(198 217 236)"
                    myStyle={{ width: "120px", margin: "auto" }}
                  />
                </Link>
              </div>
              <div className="right-item">
                <div
                  style={{
                    color: "#4d688c",
                    fontWeight: 600,
                    fontSize: "14px",
                    textAlign: "center",
                    marginTop: "8px",
                  }}
                >
                  Team Members
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    fontSize: "19px",
                    margin: "8px 0",
                    color: "#4d688c",
                  }}
                >
                  {`00${teamMembers?.totalItems}`.slice(-2)}
                </div>
                <div style={{ textAlign: "center" }}>
                  <AvatarGroup
                    max={teamMembers?.totalItems}
                    sx={{ justifyContent: "center" }}
                  >
                    {teamMembers?.data?.slice(0, 3).map((av, i) => (
                      <Avatar alt={av.firstName} src={av.profile_image} />
                    ))}
                  </AvatarGroup>
                </div>
                <div style={{ flex: 1 }}></div>
                <Link
                  to={`/exhibitor/profile?a=2`}
                  style={{ textDecoration: "none" }}
                >
                  <DashButton
                    label="Add Team"
                    color="#d17a4e"
                    hoverColor="#b55c28"
                    bgColor="#ffead2"
                    hoverBgColor="#ffbca5"
                    myStyle={{ width: "120px", margin: "auto" }}
                  />
                </Link>
              </div>
            </div>
            <div
              style={{
                margin: "12px 0",
                fontSize: "17px",
                fontWeight: 600,
                color: "#004490",
              }}
            >
              Statistics
            </div>
            <div className="dash-info-cards">
              <Link
                to={`/${getUser()?.role}/stall-viewers`}
                component={"span"}
                style={{ textDecoration: "none", flex: "1 0 calc(25% - 20px)" }}
              >
                <InfoCard
                  text="Stall Views"
                  color="#ff981f"
                  num={!isStallViewCountLoading ? stallViewCount.count : "..."}
                  icon={stallsIcon}
                />
              </Link>
              <Link
                to={`/${getUser()?.role}/profile-viewers`}
                component={"span"}
                style={{ textDecoration: "none", flex: "1 0 calc(25% - 20px)" }}
              >
                <InfoCard
                  text="Profile Views"
                  color="#fb4d24"
                  num={viewCount ? viewCount.count : "..."}
                  icon={pvsIcon}
                />
              </Link>

              <Link
                to={`/${getUser()?.role}/all-meetings`}
                component={"span"}
                style={{ textDecoration: "none", flex: "1 0 calc(25% - 20px)" }}
              >
                <InfoCard
                  text="Meeting Requests"
                  color="#004490"
                  num={meetingCount ? meetingCount.count : "..."}
                  icon={meetingIcon}
                />
              </Link>

              <InfoCard
                text="Mutual Connections"
                color="#539506"
                num={0}
                icon={connectionsIcon}
              />
            </div>
          </div>
          <div className="dash-card-35">
            <div className="dash-list-cont">
              <div style={{ display: "flex", padding: "12px" }}>
                <div style={{ color: "#004490", flex: 1, fontWeight: 600 }}>
                  Visitor Activity
                </div>
                <div
                  style={{
                    color: "#004490",
                    cursor: "pointer",
                    fontSize: "13px",
                    alignSelf: "center",
                  }}
                >
                  See All
                </div>
              </div>
              <div style={{ flex: 1, overflowY: "auto" }}>
                {/* {profileActivities.map((profile, i) => (
                <UserActvityItem
                  key={i}
                  imgUrl={profile.image}
                  name={profile.name}
                  action={profile.action}
                />
              ))} */}
                <div
                  style={{
                    color: "#ccc",
                    fontWeight: 600,
                    textAlign: "center",
                    margin: 16,
                  }}
                >
                  No Activity yet
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div style={{marginLeft: '10px', marginRight: '10px'}} className="rd-table-container">
                <table style={{borderCollapse: 'separate', border: 'none', borderSpacing: '0 10px'}}>
                <colgroup>
                    <col span="1" style={{width: '25%'}}/>
                    <col span="1" style={{width: '16%'}} />
                    <col span="1" style={{width: '16%'}} />
                    <col span="1" style={{width: '18%'}} />
                    <col span="1" style={{width: '25%'}} />
                </colgroup>
                    <tbody>
                    <tr>
    <th style={{backgroundColor: 'white', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px'}}>Profile</th>
    <th style={{backgroundColor: 'white'}}>Profile Views</th>
    <th style={{backgroundColor: 'white'}}>Profile Favourite</th>
    <th style={{backgroundColor: 'white'}}>Qr Scanned</th>
    <th style={{backgroundColor: 'white', borderTopRightRadius: '10px', borderBottomRightRadius: '10px'}}>Actions</th>
</tr>

{
    visitors.map((visitor, i)=><tr key={i}>
    <td style={{backgroundColor: 'white', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', border: 'none', padding: '10px'}}>
        <VisitorProfileItem profile={visitor} />
    </td>
    <td style={{backgroundColor: 'white', border: 'none', padding: '10px'}}><Checkbox checked/></td>
    <td style={{backgroundColor: 'white', border: 'none', padding: '10px'}}>{LONG_DASH}</td>
    <td style={{backgroundColor: 'white', border: 'none', padding: '10px'}}>{LONG_DASH}</td>
    <td style={{backgroundColor: 'white', borderTopRightRadius: '10px', borderBottomRightRadius: '10px', border: 'none', padding: '10px'}}>
    <div>
                    <IconButton aria-label="message" size="small">
                        <MessageIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton aria-label="message" size="small">
                        <CalendarMonthIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton onClick={markFav} aria-label="message" size="small">
                        <StarIcon fontSize="inherit" />
                    </IconButton>
                </div>
    </td>
</tr>)
}


             </tbody>
                </table>
            </div>          */}

        <div className="dash-cards-65-35">
          <div
            className="dash-card-65"
            style={{ display: "flex", gap: "0 16px" }}
          >
            <div
              style={{ backgroundColor: "white", borderRadius: "8px" }}
              className="dash-item-flex"
            >
              <Box
                sx={{
                  ml: 2,
                  mt: 2,
                  color: "#004490",
                  flex: 1,
                  fontWeight: 600,
                }}
              >
                Recommendations
              </Box>
              {/* <SimilarExhibitors /> */}
              <Typography mt={2} ml={2} variant="h6" color={"gray"}>
                No data yet
              </Typography>
            </div>
          </div>

          <div className="dash-card-35">
            <div className="dash-list-cont">
              <div style={{ display: "flex", padding: "12px" }}>
                <div style={{ color: "#004490", flex: 1, fontWeight: 600 }}>
                  Your Meetings
                </div>
                <Link
                  to={`/${getUser()?.role}/all-meetings`}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    style={{
                      color: "#004490",
                      cursor: "pointer",
                      fontSize: "13px",
                      alignSelf: "center",
                    }}
                  >
                    See All
                  </div>
                </Link>
              </div>
              <div
                style={{
                  flex: 1,
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  paddingBottom: "12px",
                  overflowY: "auto",
                }}
              >
                {/* <div
                  style={{
                    color: "#ccc",
                    fontWeight: 600,
                    textAlign: "center",
                    margin: 16,
                  }}
                >
                  No Meetings Yet
                </div> */}
                {isMeetingsLoading && <CircularProgress />}
                {meetings?.items?.slice(0, 2).map((item, i) => (
                  <MeetingCard meetingItem={item} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Dashboard;
