import React from "react";
import { Chip, Typography, Box, Stack } from "@mui/material";

export const MySelectedProductCats = ({ items }) => {
  // Group items by mainCat
  const groupedItems = items.reduce((acc, item) => {
    if (!acc[item.mainCat]) {
      acc[item.mainCat] = [];
    }
    acc[item.mainCat].push(item);
    return acc;
  }, {});

  return (
    <div>
      {Object.entries(groupedItems).map(([mainCat, itemsInGroup]) => (
        <Box key={mainCat} mb={4}>
          <Typography variant="h6" gutterBottom>
            {mainCat}
          </Typography>
          <Stack gap={1} direction="row" spacing={1} flexWrap="wrap">
            {itemsInGroup.map((item, index) => (
              <Chip key={index} label={item.subSubCat} />
            ))}
          </Stack>
        </Box>
      ))}
    </div>
  );
};
