import React, { useContext, useEffect, useState } from "react";
import useRegistrationSettings from "./registration/hooks/useRegistrationSettings";
import FormComponent from "./registration/FormComponent";
import MuPb from "../../../widgets/MuPb";
import { RegFormContext } from "./registration/context/RegFormContext";
import { Box, Button, Container, Stack } from "@mui/material";
import { UserContext } from "../../context/UserContext";
import ProfileBasicDisplay from "../ProfileBasicDisplay";
import { LoadingButton } from "@mui/lab";
import { validateForm } from "./registration/utils/utils";
import { toast } from "react-toastify";
import { getHeader } from "../../../utility/utility";
import axios from "axios";
import { API_ENDPOINT } from "../../../constants/constants";
import { toastError } from "../../../utils2024/utils";

const VisitorProfile = () => {
  const { data: formConfig, error, loading } = useRegistrationSettings();
  const { formData, updateFormData, initUser } = useContext(RegFormContext);
  const { user, loading: profileLoading, reloadUser } = useContext(UserContext);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (formConfig && user) {
      initUser(user);
    }
  }, [formConfig, user]);

  if (loading || profileLoading) {
    return <MuPb />;
  }
  const onSave = async () => {
    const errors = validateForm(formData, formConfig);
    if (errors.length > 0) {
      const error = errors[0];
      let field = document.getElementById(error.id);
      field.scrollIntoView({ behavior: "smooth", block: "center" });
      field.style.border = "1px solid red";
      setTimeout(() => {
        field.style.border = "";
      }, 3000);
      toast.error(`Field ${error.id} ${error.message}`);
    } else {
      setUpdating(true);
      try {
        const { data } = await axios.post(
          `${API_ENDPOINT}user/update-visitor-profile`,
          formData,
          getHeader()
        );
        toast.success(data.message);
      } catch (error) {
        toastError(error);
      } finally {
        setUpdating(false);
      }
    }
  };
  return (
    <Container sx={{ mt: 1 }}>
      <Box mb={2}>
        <ProfileBasicDisplay />
      </Box>
      <FormComponent formConfig={formConfig} />
      {!formData.preview && (
        <Box justifyContent={"center"} mt={2} mb={2} display={"flex"}>
          <LoadingButton
            onClick={onSave}
            sx={{ width: 240 }}
            variant="contained"
          >
            Submit
          </LoadingButton>
        </Box>
      )}
    </Container>
  );
};

export default VisitorProfile;
