import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import HotTubIcon from "@mui/icons-material/HotTub";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SummarizeIcon from "@mui/icons-material/Summarize";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FloodIcon from "@mui/icons-material/Flood";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import BadgeIcon from "@mui/icons-material/Badge";
import {
  ADMIN,
  DEFAULT_DP,
  EXHIBITOR,
  EXHIBITOR_TOKEN,
  MAIN_DOMAIN,
  USER,
} from "../constants/constants";
import ExploreIcon from "@mui/icons-material/Explore";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import tinycolor from "tinycolor2";
import { useLocation } from "react-router-dom";
export const VISITOR_ROUTES = {
  dashboard: { to: "dashboard", label: "Dashboard" },
  profile: { to: "profile", label: "Profile" },
  explore: { to: "explore", label: "Explore" },
  floorPlan: { to: "floor-plan", label: "Floor Plan" },
  mySchedule: { to: "my-schedule", label: "My Schedule" },
  favorites: { to: "favorites", label: "Favorites" },
  reports: { to: "reports", label: "Reports" },
  settings: { to: "settings", label: "Settings" },
};
export const EXHIBITOR_ROUTES = {
  dashboard: { to: "dashboard", label: "Dashboard" },
  explore: { to: "explore", label: "Explore" },
  profile: { to: "profile", label: "Profile" },
  settings: { to: "settings", label: "Settings" },
  floorPlan: { to: "floor-plan", label: "Floor Plan" },
  management: { to: "management", label: "Management" },
  exhibitorServices: { to: "exhibitor-services", label: "Exhibitor Services" },
  exhibitionCatalogue: {
    to: "exhibition-catalogue",
    label: "Exhibition Catalogue",
  },
  mySchedule: { to: "my-schedule", label: "My Schedule" },
  favorites: { to: "favorites", label: "Favorites" },
  reports: { to: "reports", label: "Reports" },
  settings: { to: "settings", label: "Settings" },
};
export const VISITOR_MENU_LIST = [
  {
    to: "/visitor",
    text: VISITOR_ROUTES.dashboard.label,
    icon: DashboardIcon,
  },
  {
    to: "",
    text: VISITOR_ROUTES.explore.label,
    icon: ExploreIcon,
    sublinks: [
      {
        link: "/visitor/explore/exhibitors",
        label: "Exhibitors",
      },
      {
        link: "/visitor/explore/products",
        label: "Products",
      },
      {
        link: "/visitor/explore/ai-matching",
        label: "B2B AI Matchmaking",
      },
    ],
  },
  {
    to: "/visitor/floor-plan",
    text: VISITOR_ROUTES.floorPlan.label,
    icon: FloodIcon,
  },
  // {
  //   to: VISITOR_ROUTES.mySchedule.to,
  //   text: VISITOR_ROUTES.mySchedule.label,
  //   icon: EditCalendarIcon,
  // },
  {
    to: "/visitor/favorites",
    text: VISITOR_ROUTES.favorites.label,
    icon: FavoriteIcon,
  },
  // {
  //   to: VISITOR_ROUTES.reports.to,
  //   text: VISITOR_ROUTES.reports.label,
  //   icon: AssessmentIcon,
  // },
  {
    to: "print-badge",
    text: "Print Badge",
    icon: BadgeIcon,
  },
  // {
  //   to: VISITOR_ROUTES.settings.to,
  //   text: VISITOR_ROUTES.settings.label,
  //   icon: SettingsIcon,
  // },
];
export const EXHIBITOR_MENU_LIST = [
  {
    to: "/exhibitor",
    text: EXHIBITOR_ROUTES.dashboard.label,
    icon: DashboardIcon,
  },
  {
    to: "",
    text: EXHIBITOR_ROUTES.explore.label,
    icon: ExploreIcon,
    sublinks: [
      {
        link: "/exhibitor/explore/exhibitors",
        label: "Exhibitors",
      },
      {
        link: "/exhibitor/explore/products",
        label: "Products",
      },
      {
        link: "/exhibitor/explore/ai-matching",
        label: "B2B AI Matchmaking",
      },
      {
        link: "/exhibitor/explore/visitors",
        label: "Visitors",
      },
    ],
  },
  {
    to: "/exhibitor/floor-plan",
    text: EXHIBITOR_ROUTES.floorPlan.label,
    icon: HomeWorkIcon,
  },
  {
    to: "/exhibitor/oes-forms",
    text: EXHIBITOR_ROUTES.exhibitorServices.label,
    icon: HotTubIcon,
  },
  {
    to: "/exhibitor/exhibition-catalogue",
    text: EXHIBITOR_ROUTES.exhibitionCatalogue.label,
    icon: DesignServicesIcon,
  },
  // {
  //   to: EXHIBITOR_ROUTES.mySchedule.to,
  //   text: EXHIBITOR_ROUTES.mySchedule.label,
  //   icon: EditCalendarIcon,
  // },
  {
    to: "/exhibitor/favorites",
    text: EXHIBITOR_ROUTES.favorites.label,
    icon: FavoriteIcon,
  },
  // {
  //   to: EXHIBITOR_ROUTES.reports.to,
  //   text: EXHIBITOR_ROUTES.reports.label,
  //   icon: SummarizeIcon,
  // },
  // {
  //   to: EXHIBITOR_ROUTES.settings.to,
  //   text: EXHIBITOR_ROUTES.settings.label,
  //   icon: SettingsIcon,
  // },
];

export function hexToRGBA(hex, alpha = 1) {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export const saveUser = (user) => {
  localStorage.setItem(USER, JSON.stringify(user));
};
export const getUser = () => {
  return JSON.parse(localStorage.getItem(USER));
};
export const deleteUser = () => {
  localStorage.removeItem(USER);
};
export const isLoggedIn = () => {
  return localStorage.getItem(USER) !== null;
};

export const saveExhibitor = (user) => {
  localStorage.setItem(EXHIBITOR, JSON.stringify(user));
};
export const getExhibitor = () => {
  return JSON.parse(localStorage.getItem(EXHIBITOR));
};
export const saveExhibitorToken = (token) => {
  localStorage.setItem(EXHIBITOR_TOKEN, token);
};
export const getExhibitorToken = () => {
  return localStorage.getItem(EXHIBITOR_TOKEN);
};
export const getExhibitorHeader = () => {
  return {
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${getExhibitorToken()}`,
    },
  };
};
export const getExhibitorMultipartHeader = () => {
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: `Bearer ${getExhibitorToken()}`,
    },
  };
};
export function getHoverColor(baseColor) {
  const hoverColor = tinycolor(baseColor).brighten(10).toString();
  return hoverColor;
}
export function getContrastingTextColor(hexColor) {
  const color = new tinycolor(hexColor);
  return color.isLight() ? "black" : "white";
}

export const deleteExhibitor = () => {
  localStorage.removeItem(EXHIBITOR_TOKEN);
};
export const isExhibitorLoggedIn = () => {
  return localStorage.getItem(EXHIBITOR) !== null;
};

//This is temp

export const saveAdmin = (admin) => {
  localStorage.setItem(ADMIN, JSON.stringify(admin));
};
export const getAdmin = () => {
  return JSON.parse(localStorage.getItem(ADMIN));
};
export const deleteAdmin = () => {
  localStorage.removeItem(ADMIN);
};
export const isAdminLoggedIn = () => {
  return localStorage.getItem(ADMIN) !== null;
};
export function isValidMongoDbId(str) {
  return /^[0-9a-fA-F]{24}$/.test(str);
}

export const getProfileImage = () => {
  return getUser().profile_image !== undefined
    ? getUser().profile_image
    : DEFAULT_DP;
};
export const getExhibitorProfileImage = () => {
  return getExhibitor().profile_image !== undefined
    ? getExhibitor().profile_image.value
    : DEFAULT_DP;
};
export const getHeader = () => {
  return {
    headers: {
      "content-type": "application/json",
      token: getUser()?.token,
    },
  };
};
export const getMultipartHeader = () => {
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      token: getUser()?.token,
    },
  };
};
export const getVisitorHeader = () => {
  return {
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${getUser().token}`,
    },
  };
};

export const getCommonHeaderVE = () => {
  let userToken = getUser()?.token;
  let exhibitorToken = getExhibitorToken();
  let adminToken = getAdmin()?.token;
  return {
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${
        userToken ? userToken : exhibitorToken ? exhibitorToken : adminToken
      }`,
    },
  };
};
export const getAdminHeader = () => {
  return {
    headers: {
      "content-type": "application/json",
      token: getAdmin()?.token,
    },
  };
};

export const getAdminMultipartHeader = () => {
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      token: getAdmin()?.token,
    },
  };
};

export const formatToSimpleDate = (dateString) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  const date = new Date(dateString);

  return date.toLocaleDateString("en-US", options);
};
export const TITLES = ["Mr.", "Mrs.", "Ms.", "Miss.", "Dr.", "Prof."];

export const getUrlWithProtocol = (url) => {
  if (!url) {
    return "#";
  }
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  } else {
    return `http://${url}`;
  }
};
export const calculateFilledPercentage = (obj) => {
  // Properties that should be checked for content
  const propertiesToCheck = [
    "user_id",
    "barcode_id",
    "title",
    "firstName",
    "lastName",
    "phone",
    "email",
    "companyName",
    "address",
    "selectedCountry",
    "selectedState",
    "selectedCity",
    "pin",
    "website",
    "telephone",
    "fbCheck",
    "instaCheck",
    "twitterCheck",
    "linkedInCheck",
    "fbLink",
    "instaLink",
    "twitterLink",
    "linkedInLink",
    "designation",
    "department",
    "selectedEventSource",
    "selectedIndustrySector",
    "selectedCategories",
    "qrLink",
    "lookingFor",
  ];

  let filledCount = 0;

  propertiesToCheck.forEach((prop) => {
    const value = obj[prop];

    if (Array.isArray(value) && value.length > 0) {
      filledCount++;
    } else if (typeof value === "string" && value.trim() !== "") {
      filledCount++;
    } else if (typeof value !== "undefined" && value !== null) {
      filledCount++;
    }
  });

  const percentage = (filledCount / propertiesToCheck.length) * 100;
  return Math.ceil(percentage); // Using Math.ceil to round up to the nearest whole number
};
export const exhibitorFilledPercentage = (obj) => {
  const propertiesToCheck = [
    "profile_image",
    "companyName",
    "companyVideo",
    "contactSalutation",
    "contactFirstName",
    "contactLastName",
    "contactDesignation",
    "mobile",
    "companyEmail",
    "contactEmail",
    "address1",
    "address2",
    "address3",
    "country",
    "state",
    "city",
    "pin",
    "phone",
    "fax",
    "website",
    "companyShortName",
    "fascia",
    "weAre",
    "industrySector",
    "exhibitorCategory",
    "productCategories",
  ];
  let filledCount = 0;

  propertiesToCheck.forEach((prop) => {
    const value = obj[prop];

    if (Array.isArray(value) && value.length > 0) {
      filledCount++;
    } else if (typeof value === "string" && value.trim() !== "") {
      filledCount++;
    } else if (typeof value !== "undefined" && value !== null) {
      filledCount++;
    }
  });

  const percentage = (filledCount / propertiesToCheck.length) * 100;
  return Math.ceil(percentage); // Using Math.ceil to round up to the nearest whole number
};
export const getOesComp = (num) => {};
export const timeAgo = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();

  const secondsPast = (now.getTime() - date.getTime()) / 1000;

  // Less than a minute
  if (secondsPast < 60) {
    return "Just now";
  }

  // Minutes
  if (secondsPast < 3600) {
    return `${Math.round(secondsPast / 60)} minute${
      Math.round(secondsPast / 60) > 1 ? "s" : ""
    } ago`;
  }

  // Hours
  if (secondsPast <= 86400) {
    return `${Math.round(secondsPast / 3600)} hour${
      Math.round(secondsPast / 3600) > 1 ? "s" : ""
    } ago`;
  }

  // Days
  if (secondsPast <= 604800) {
    return `${Math.round(secondsPast / 86400)} day${
      Math.round(secondsPast / 86400) > 1 ? "s" : ""
    } ago`;
  }

  // Weeks
  if (secondsPast <= 2419200) {
    return `${Math.round(secondsPast / 604800)} week${
      Math.round(secondsPast / 604800) > 1 ? "s" : ""
    } ago`;
  }

  // Months (approximated at 30.44 days per month)
  if (secondsPast <= 29030400) {
    return `${Math.round(secondsPast / 2419200)} month${
      Math.round(secondsPast / 2419200) > 1 ? "s" : ""
    } ago`;
  }

  // Years
  return `${Math.round(secondsPast / 29030400)} year${
    Math.round(secondsPast / 29030400) > 1 ? "s" : ""
  } ago`;
};
export function toShortHumanReadableDate(isoString, locale = "en-US") {
  const dateObj = new Date(isoString);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return `${dateObj.toLocaleDateString(locale, options)}`;
}
export const getDateFormat = (d) => {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let y = d.getFullYear();
  let dd = "0" + d.getDate();
  let h = "0" + d.getHours();
  let min = "0" + d.getMinutes();
  return (
    dd.slice(-2) +
    " " +
    months[d.getMonth()] +
    " " +
    y +
    ", " +
    h.slice(-2) +
    ":" +
    min.slice(-2)
  );
};
export function extractTime(isoString) {
  const dateObj = new Date(isoString);
  return dateObj.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
}
export function extractDate(isoString, lc = "en-GB") {
  const dateObj = new Date(isoString);
  return dateObj.toLocaleDateString(lc); // en-GB format is dd/mm/yyyy
}
export function getFileSize(file, unit) {
  const sizeInBytes = file.size;
  let convertedSize;

  switch (unit) {
    case "bytes":
      return sizeInBytes + " bytes";
    case "KB":
      convertedSize = sizeInBytes / 1024;
      return convertedSize.toFixed(2) + " KB";
    case "MB":
      convertedSize = sizeInBytes / (1024 * 1024);
      return convertedSize.toFixed(2) + " MB";
    case "GB":
      convertedSize = sizeInBytes / (1024 * 1024 * 1024);
      return convertedSize.toFixed(2) + " GB";
    default:
      throw new Error(
        'Invalid unit. Please choose from "bytes", "KB", "MB", or "GB".'
      );
  }
}
export function getFileSizeOnlyValue(file, unit) {
  const sizeInBytes = file.size;
  let convertedSize;

  switch (unit) {
    case "bytes":
      return sizeInBytes;
    case "KB":
      convertedSize = sizeInBytes / 1024;
      return Number(convertedSize.toFixed(2));
    case "MB":
      convertedSize = sizeInBytes / (1024 * 1024);
      return Number(convertedSize.toFixed(2));
    case "GB":
      convertedSize = sizeInBytes / (1024 * 1024 * 1024);
      return Number(convertedSize.toFixed(2));
    default:
      throw new Error(
        'Invalid unit. Please choose from "bytes", "KB", "MB", or "GB".'
      );
  }
}
export function isNullOrEmpty(value) {
  return (
    value === null ||
    value === undefined ||
    value === "" ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === "object" && Object.keys(value).length === 0)
  );
}
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// utils.js or utils/base64.js
export const base64ToBlob = (base64Data, contentType = "") => {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }

  return new Blob(byteArrays, { type: contentType });
};
export function detectSubdomainAndDash(hostname) {
  if (hostname.endsWith(MAIN_DOMAIN)) {
    // Note: If the hostname is exactly the MAIN_DOMAIN, subdomainPart will be an empty string
    const subdomainPart = hostname.replace(`.${MAIN_DOMAIN}`, "");
    if (subdomainPart && subdomainPart !== hostname) {
      // Checks if there's a subdomain
      const hasDash = subdomainPart.includes("-");
      return { hasSubdomain: true, hasDash };
    }
  }
  return { hasSubdomain: false, hasDash: false };
}
export function roundToTwoDecimals(num) {
  return parseFloat(num.toFixed(2));
}
