import React, { useContext, useEffect, useState } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import parse from "html-react-parser";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import StarIcon from "@mui/icons-material/Star";
import ForumIcon from "@mui/icons-material/Forum";
import StarRateIcon from "@mui/icons-material/StarRate";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tab,
  Tabs,
} from "@mui/material";
import {
  getUrlWithProtocol,
  getUser,
  useQuery,
} from "../../../../utility/utility";

import { useRef } from "react";
import "cropperjs/dist/cropper.css";
import RdIconContainer from "../../../../widgets/RdIconContainer";
import EditIcon from "@mui/icons-material/Edit";
import pgBg from "../../../../assets/profile_cover_default.jpeg";
import xLogo from "../../../../assets/x_logo.png";
import {
  API_ENDPOINT,
  DEFAULT_DP,
  ICON_TYPE_NON_IMAGE,
  PROFILE_BANNER,
  PROFILE_IMAGE,
  USER,
} from "../../../../constants/constants";
import About from "./About";
import Products from "./Products";
import TeamMembers from "./TeamMembers";
import UploadContent from "./UploadContent";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PublicIcon from "@mui/icons-material/Public";
import DashButton from "../../../../widgets/DashButton";
import RdMyModal from "../../../../widgets/myModal/RdMyModal";
import ProfileCropEdit from "./ProfileCropEdit";
import HeaderLabel from "../../../../widgets/HeaderLabel";
import AddCompanyVideo from "../AddCompanyVideo";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SocialLinksEdit from "./edit/SocialLinksEdit";
import useApiEndpoint from "../../../events/hooks/useApiEndpoint";
import { UserContext } from "../../../context/UserContext";
import MuPb from "../../../../widgets/MuPb";
const ProfileDetails = ({ isView = false, id }) => {
  const { user, loading } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useQuery();
  const actionTab = queryParams.get("a"); // This will be 'addteam' for the URL you provided

  const [videoMode, setViewMode] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [currentTab, setCurrentTab] = useState(
    actionTab ? parseInt(actionTab) : 0
  );
  const [uploadType, setUploadType] = useState(PROFILE_IMAGE);
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [showAddVideo, setShowAddVideo] = useState(false);
  const [showEditSocial, setShowEditSocial] = useState(false);
  useEffect(() => {
    setViewMode(isView);
  }, [isView]);
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("a", newValue);
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };
  const onEditProfileClick = () => {
    setUploadType(PROFILE_IMAGE);
    setShowImageUpload(true);
  };
  const onEditBannerClick = () => {
    setUploadType(PROFILE_BANNER);
    setShowImageUpload(true);
  };
  const onVideoAdded = () => {
    setShowAddVideo(false);
  };
  if (loading) {
    return <MuPb />;
  }
  return (
    <div className="rd-dash-content">
      <RdMyModal
        showModal={showEditSocial}
        setShowModal={setShowEditSocial}
        modalC={
          <SocialLinksEdit
            title="Update Social Links"
            user={user}
            show={showEditSocial}
            setShow={setShowEditSocial}
          />
        }
      />
      <RdMyModal
        showModal={showImageUpload}
        setShowModal={setShowImageUpload}
        modalC={
          <ProfileCropEdit
            show={showImageUpload}
            setShow={setShowImageUpload}
            upload_type={uploadType}
          />
        }
      />
      <RdMyModal
        mWidth={640}
        showModal={showAddVideo}
        setShowModal={setShowAddVideo}
        modalC={
          <AddCompanyVideo
            exhibitor={user}
            onAdded={onVideoAdded}
            showAdd={showAddVideo}
          />
        }
      />
      <div
        className="dash-30-70"
        style={{ maxWidth: "1200px", marginLeft: "auto", marginRight: "auto" }}
      >
        <div className="dash-30">
          <div
            style={{
              borderRadius: "8px",
              boxShadow: "0px 4px 8px rgba(241, 233, 231, 0.5)",
              backgroundColor: "white",
              padding: "10px",
              margin: "0 12px",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "table",
                margin: "auto",
              }}
            >
              <img
                src={
                  user.profile_image !== undefined
                    ? user.profile_image
                    : DEFAULT_DP
                }
                alt="profile"
                style={{
                  width: "90px",
                  height: "90px",
                  borderRadius: "50%",
                  border: "1px solid #b68337",
                  cursor: "pointer",
                }}
              />
              {!videoMode && (
                <RdIconContainer
                  icon={{
                    icon: <EditIcon sx={{ color: "#777" }} />,
                    type: ICON_TYPE_NON_IMAGE,
                    desc: "edit icon",
                    show: true,
                  }}
                  bg="#ddd"
                  hoverBg="#eee"
                  myStyle={{
                    position: "absolute",
                    bottom: 0,
                    right: "-32px",
                  }}
                  onClick={onEditProfileClick}
                />
              )}
            </div>
            <div className="ex-prof-comp-name">{user.companyName.value}</div>
            <div
              style={{
                display: "flex",
                margin: "16px 0 10px 0",
                gap: "12px",
                justifyContent: "center",
              }}
            >
              {!isView ? (
                <>
                  <DashButton
                    label="Preview Profile"
                    onClick={() => setViewMode(true)}
                    color="#d17a4e"
                    hoverColor="#b55c28"
                    bgColor="#ffead2"
                    hoverBgColor="#ffbca5"
                    myStyle={{ width: "120px" }}
                  />
                  <DashButton
                    onClick={() => setViewMode(false)}
                    label="Update Profile"
                    color="rgb(47 146 188)"
                    hoverColor="rgb(21 80 106)"
                    bgColor="rgb(229 241 253)"
                    hoverBgColor="rgb(198 217 236)"
                    myStyle={{ width: "120px" }}
                  />
                </>
              ) : (
                <>
                  <Link to={`/${getUser()?.role}/chat?tid=${user.username}`}>
                    <Button
                      size="small"
                      sx={{
                        fontSize: "11px",
                        backgroundColor: "#dbedff",
                        color: "#35b0e1",
                        textTransform: "none",
                        borderRadius: "20px",
                        padding: "2px 10px",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        "&:hover": {
                          backgroundColor: "#c2d1f0",
                          color: "#1a94b7",
                          boxShadow: "0 6px 8px rgba(0, 0, 0, 0.1)",
                        },
                      }}
                      className="chat-mui-btn"
                      variant="contained"
                      startIcon={<ForumIcon />}
                    >
                      Message
                    </Button>
                  </Link>
                  <Link
                    to={`/${getUser()?.role}/meeting-scheduler?tid=${
                      user.username
                    }`}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      startIcon={
                        <CalendarMonthIcon style={{ color: "#ffa43d" }} />
                      }
                      sx={{
                        fontSize: "11px",
                        backgroundColor: "#ffead2",
                        color: "#ffa43d",
                        textTransform: "none",
                        borderRadius: "20px",
                        padding: "2px 10px",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        "&:hover": {
                          backgroundColor: "#fddbb1",
                          color: "#ff8c00",
                          boxShadow: "0 6px 8px rgba(0, 0, 0, 0.1)",
                        },
                      }}
                    >
                      Meeting
                    </Button>
                  </Link>
                  <Link to={`/${getUser()?.role}/favorites?id=${user.user_id}`}>
                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<StarRateIcon style={{ color: "#fb4d24" }} />}
                      sx={{
                        fontSize: "11px",
                        backgroundColor: "#ffe4de",
                        color: "#fb4d24",
                        borderRadius: "20px",
                        textTransform: "none",
                        padding: "2px 10px",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        "&:hover": {
                          backgroundColor: "#ffc9b8",
                          color: "#e4411a",
                          boxShadow: "0 6px 8px rgba(0, 0, 0, 0.1)",
                        },
                      }}
                    >
                      Favorite
                    </Button>
                  </Link>
                </>
              )}
            </div>
          </div>
          <div
            style={{
              borderRadius: "8px",
              boxShadow: "0px 4px 8px rgba(241, 233, 231, 0.5)",
              backgroundColor: "white",
              padding: "10px 16px",
              margin: "12px",
            }}
          >
            <div style={{ fontWeight: 600, color: "#004490" }}>Information</div>
            <div style={{ marginTop: "8px", marginBottom: "16px" }}>
              <Link to={`/hall-view/${user.hallNumber.value}`}>
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <LocationOnIcon fontSize="17px" sx={{ color: "#777" }} />
                  <div
                    style={{
                      marginLeft: "8px",
                      color: "#777",
                      fontWeight: 600,
                      fontSize: "14px",
                      alignSelf: "center",
                    }}
                  >
                    {user.hallNumber.value} / {user.standNumber.value}
                  </div>
                </div>
              </Link>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <PublicIcon fontSize="17px" sx={{ color: "#777" }} />
                <div style={{ marginLeft: "8px", alignSelf: "center" }}>
                  {user.website.value !== "" ? (
                    <a
                      href={getUrlWithProtocol(user.website.value)}
                      target="_blank"
                    >
                      {user.website.value}
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <HeaderLabel
              showEdit={!videoMode}
              label="Social Media"
              onEdit={() => setShowEditSocial(true)}
            />
            <div style={{ display: "flex", marginTop: "4px" }}>
              <IconButton
                component={"a"}
                href={getUrlWithProtocol(user?.socialLinks?.value?.facebook)}
                target="_blank"
              >
                <FacebookIcon style={{ color: "blue" }} />
              </IconButton>
              <IconButton
                component={"a"}
                href={getUrlWithProtocol(user?.socialLinks?.value?.instagram)}
                target="_blank"
              >
                <InstagramIcon style={{ color: "#E1306C" }} />
              </IconButton>
              <IconButton
                component={"a"}
                href={getUrlWithProtocol(user?.socialLinks?.value?.linkedin)}
                target="_blank"
              >
                <LinkedInIcon style={{ color: "#2867B2" }} />
              </IconButton>
              <IconButton
                sx={{ height: 40, width: 40 }}
                component={"a"}
                href={getUrlWithProtocol(user?.socialLinks?.value?.twitter)}
                target="_blank"
              >
                <img
                  src={xLogo}
                  alt="x"
                  style={{ height: "20px", width: "20px" }}
                />
              </IconButton>
            </div>
          </div>
          <div style={{ margin: "12px" }}>
            <HeaderLabel
              showEdit={!videoMode}
              label="Company Video"
              onEdit={() => setShowAddVideo(true)}
            />
            {user.companyVideo && (
              <div style={{ marginTop: "16px" }} class="responsive-content">
                {parse(user.companyVideo)}
              </div>
            )}
            {!user.companyVideo && (
              <div
                style={{
                  margin: "16px",
                  fontSize: "20px",
                  color: "#888",
                  fontWeight: 600,
                }}
              >
                No Video Yet
              </div>
            )}
          </div>
        </div>
        <div className="dash-70">
          <div
            style={{
              position: "relative",
              height: "172px",
              backgroundImage: `url(${
                user.profile_banner === undefined || user.profile_banner === ""
                  ? pgBg
                  : user.profile_banner
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center", // added this line
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              backgroundRepeat: "no-repeat",
            }}
          >
            {!videoMode && (
              <RdIconContainer
                icon={{
                  icon: <EditIcon sx={{ color: "green" }} />,
                  type: ICON_TYPE_NON_IMAGE,
                  desc: "edit icon banner",
                  show: true,
                }}
                bg="#ddd"
                hoverBg="#eee"
                myStyle={{ position: "absolute", top: 0, right: "0" }}
                onClick={onEditBannerClick}
              />
            )}
          </div>
          <Box
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.1)",
            }}
          >
            <Tabs
              value={currentTab}
              variant="scrollable"
              scrollButtons={false}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: { backgroundColor: "#f4741f" },
              }}
            >
              <Tab label="About" sx={{ textTransform: "none" }} />
              <Tab label="Products" sx={{ textTransform: "none" }} />
              <Tab label="Team Members" sx={{ textTransform: "none" }} />
              <Tab label="Upload Content" sx={{ textTransform: "none" }} />
            </Tabs>
          </Box>
          <div style={{ marginTop: "12px" }}>
            {currentTab === 0 ? (
              <About
                id={id}
                isView={videoMode}
                exhibitor={user}
                setCurrentTab={setCurrentTab}
              />
            ) : currentTab === 1 ? (
              <Products
                id={id}
                isView={videoMode}
                exhibitor={user}
                setCurrentTab={setCurrentTab}
              />
            ) : currentTab === 2 ? (
              <TeamMembers id={id} isView={videoMode} exhibitor={user} />
            ) : (
              <UploadContent id={id} isView={videoMode} exhibitor={user} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
