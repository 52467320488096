import AdditionalServices from "../components/dashboard/exhibitor/oes/AdditionalServices";
import Advertisement from "../components/dashboard/exhibitor/oes/Advertisement";
import AudioEquipment from "../components/dashboard/exhibitor/oes/AudioEquipment";
import EcoDesign from "../components/dashboard/exhibitor/oes/EcoDesign";
import ElectricalWork from "../components/dashboard/exhibitor/oes/ElectricalWork";
import ExhibitorBadges from "../components/dashboard/exhibitor/oes/ExhibitorBadges";
import ExitPass from "../components/dashboard/exhibitor/oes/ExitPass";
import GuidelinesMezzanine from "../components/dashboard/exhibitor/oes/GuidelinesMezzanine";
import Horticulture from "../components/dashboard/exhibitor/oes/Horticulture";
import HotelAccomodation from "../components/dashboard/exhibitor/oes/HotelAccomodation";
import InvitationInaguaration from "../components/dashboard/exhibitor/oes/InvitationInaguaration";
import MandatoryDesign from "../components/dashboard/exhibitor/oes/MandatoryDesign";
import MandatoryFormA from "../components/dashboard/exhibitor/oes/MandatoryFormA";
import MandatoryFormB from "../components/dashboard/exhibitor/oes/MandatoryFormB";
import MandatoryNote from "../components/dashboard/exhibitor/oes/MandatoryNote";
import Manual from "../components/dashboard/exhibitor/oes/Manual";
import Security from "../components/dashboard/exhibitor/oes/Security";
import SeminarHalls from "../components/dashboard/exhibitor/oes/SeminarHalls";
import ServiceBadges from "../components/dashboard/exhibitor/oes/ServiceBadges";
import SetupSchedule from "../components/dashboard/exhibitor/oes/SetupSchedule";
import ShowDaily from "../components/dashboard/exhibitor/oes/ShowDaily";
import SiteHandling from "../components/dashboard/exhibitor/oes/SiteHandling";
import StandPossession from "../components/dashboard/exhibitor/oes/StandPossession";
import Summary from "../components/dashboard/exhibitor/oes/Summary";
import TemporaryPersonnel from "../components/dashboard/exhibitor/oes/TemporaryPersonnel";
import Translators from "../components/dashboard/exhibitor/oes/Translators";
import UtilityDetails from "../components/dashboard/exhibitor/oes/UtilityDetails";
import VisaApplication from "../components/dashboard/exhibitor/oes/VisaApplication";
import exhibitorIcon from "../assets/exhibitor_icon.png";
export const ICON_TYPE_NON_IMAGE = "icon_type_non_image";
export const ICON_TYPE_IMAGE = "icon_type_image";
export const HEADER_RIGHT_NOTIFICATION = "header_right_notification";
export const HEADER_RIGHT_MESSAGE = "header_right_message";
export const HEADER_RIGHT_PROFILE = "header_right_profile";
export const MOBILE_THRESHOLD = 640;
export const VISITOR = "visitor";
export const VISITORS = "visitors";
export const EXHIBITOR = "exhibitor";
export const IMTEX_EXHIBITOR = "imtexExhibitor";
export const EXHIBITOR_TOKEN = "exhibitor_token";
export const PROFILE_IMAGE = "profile_image";
export const PROFILE_BANNER = "profile_banner";
export const MEETINGS = "meetings";
export const USER = "user";
export const ADMIN = "admin";
export const LONG_DASH = "—";
export const APP_LOGO = "/app_logo.png";
export const HEADER_RIGHT_MODE_SETTINGS = "header_right_mode_settings";
export const TRANSPARENCY = 0.8;
export const DRAWER_WIDTH = 240;
export const MAIN_DOMAIN = "expoplanner.in";
export const EVENT_NAME = "";
export const LOCAL = "local";
export const PRODUCTION = "production";
export const state = PRODUCTION;
export const API_ENDPOINT =
  state === PRODUCTION
    ? "https://apis.expoplanner.in/"
    : "http://localhost:5002/";
export const VISITOR_API = `${API_ENDPOINT}visitor/`;
export const EXHIBITOR_API = `${API_ENDPOINT}exhibitor/`;
export const INTERACTION_API = `${API_ENDPOINT}interactions/`;
export const EVENT_API = `${API_ENDPOINT}event/`;
export const ADMIN_API = `${API_ENDPOINT}admin`;
export const DEFAULT_DP = `https://s3.ap-south-1.amazonaws.com/expoplanner.in/uploads/expo_expo_placeholder.png`;
export const EXHIBITOR_PLACEHOLDER =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/DALL%C2%B7E+2024-03-25+10.05.56+-+Design+a+simplistic+logo+for+an+exhibitor+company%2C+featuring+a+stylized+version+of+a+company+building.+The+logo+should+be+minimalistic%2C+utilizing+clea.webp";
export const EXHIBITOR_PLACEHOLDER2 =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/exhibitor_placeholder.png";
export const DEFAULT_EX_ICON = exhibitorIcon;
export const INTERACT_MEETING = "interact_meeting";
export const INTERACT_MESSAGE = "interact_message";
export const INTERACT_FAVORITE = "interact_favorite";
export const LOGIN_BANNER =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/965c4ea4-e89f-4ad1-88e0-829979f1f1d5.jpeg";

export const APP_HEADER_LOGO =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_+Organiser.webp";

export const EMAIL_HEADER =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/PMTX+2024+-+Email+Header.png";
export const EMAIL_FOOTER =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/uploads/PMTX2024-EmailFooter.webp";
export const VISITOR_REG_HEADER =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/Header+1200x150.png";
export const CONFIRMATION_HEADER =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/Header2.png";
export const BADGE_BG =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX2025BadgeBG.png";
export const LOGIN_HEADER_BG =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/IMTEX+25+_+Password+Banner.webp";
export const OES_MANUAL =
  "https://s3.ap-south-1.amazonaws.com/expoplanner.in/PMTX+2024+-+Exhibitor+Manual+(15+April+2024).pdf";
export const MODULES = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
    ["link", "image"], // this line adds the image and video options
  ],
};
export const OES_COMPONENTS = [
  Manual,
  SetupSchedule,
  UtilityDetails,
  StandPossession,
  InvitationInaguaration,
  ExhibitorBadges,
  ElectricalWork,
  SiteHandling,
  AudioEquipment,
  ServiceBadges,
  ExitPass,
  GuidelinesMezzanine,
  Security,
  HotelAccomodation,
  SeminarHalls,
  TemporaryPersonnel,
  Translators,
  Horticulture,
  Advertisement,
  ShowDaily,
  VisaApplication,
  AdditionalServices,
  EcoDesign,
  MandatoryDesign,
  MandatoryNote,
  MandatoryFormA,
  MandatoryFormB,
  Summary,
];

export const SUPPLIER_LIST = [
  {
    form_number: 2,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 3,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 4,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 5,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 6,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 7,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 8,
    supplier_name: "R.E. Rogers India Pvt. Ltd",
    address:
      "R.E.R. House, 2383/1, 11th Main, 15th Cross ‘E’ Block, Opp. to State Bank of Mysore Sahakara Nagar, Bangalo - 560092, Karnataka, India ",
    contact_person: "Mr. Surendhar | Mr. Pravin Kadam |Mr. Damodar",
    email_id: [
      "surender@rogersworldwideindia.com",
      "pravin@rogersworldwideindia.com",
      "damodar@rogersworldwideindia.com",
    ],
    phone_number: "+91-9945083076 | +91-8956711226 | +91-9920108787",
    deadline_date: "15/04/2024",
  },

  {
    form_number: 8,
    supplier_name: "P S Bedi & Company Pvt. Ltd",
    address:
      "D - 14 /1, Okhla Industrial Area, Phase - 1, New Delhi - 110020, India",
    contact_person: "Mr. C Sudhir Babu | Mr. Jatin Bharadwaj",
    email_id: ["cs1exh@psbedi.com", "mumbai@psbedi.com"],
    phone_number: "+91-9315654664 | +91-9810146613 | +91-9910201927",
    deadline_date: "15/04/2024",
  },
  {
    form_number: 9,
    supplier_name: "Shri Ganesh Audio Visual",
    address:
      "Deals in LCD Projector Sales, Service & Hiring, Flat No. 404 B, Survey No. 80/2/1, Pol Residency, Near Atul Nagar, Dodake Park, Warje, Pune – 411058, India",
    contact_person: "Mr. Santosh Gujar",
    email_id: ["shriganeshaudiovisual@gmail.com"],
    phone_number: "+91-8007862585 | 9822862585",
    deadline_date: "10/05/2024",
  },
  {
    form_number: 10,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 11,
    supplier_name: "R.E. Rogers India Pvt. Ltd",
    address:
      "R.E.R. House, 2383/1, 11th Main, 15th Cross 'E' Block Opp. to State Bank of Mysore Sahakara Nagar Bangalore - 560092, Karnataka, India",
    contact_person: "Mr. Surendhar | Mr. Rajath Naik",
    email_id: [
      "surender@rogersworldwideindia.com",
      "rajath@rogersworldwideindia.com",
    ],
    phone_number: "+91-80-42690500-55|+91-9945083076|9901597909",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 11,
    supplier_name: "P S Bedi & Company Pvt. Ltd",
    address:
      "D - 14 /1, Okhla Industrial Area, Phase - 1, New Delhi - 110020, Delhi, India",
    contact_person: "Mr. Shrenik Jain | Mr. C. Sudhir Babu",
    email_id: ["sjain@psbedi.com", "cs1exh@psbedi.com"],
    phone_number: "+91-11-46055200|+91-9538205424|9315654664",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 11,
    supplier_name: "Schenker India Pvt. Ltd",
    address:
      "101, Touch Down, 1st Floor, No. 1 & 2 HAL Industrial Area, Adjacent to HAL Museum, Airport Road, Bangalore - 560037, Karnataka, India",
    contact_person: "Mr. Vignesh Bharadwaj | Mr. Prabodh Kulkarni",
    email_id: [
      "vignesh.bharadwaj@dbschenker.com",
      "prabodh.kulkarni-extern@dbschenker.com",
    ],
    phone_number: "+91 9884029200|9319283395",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 12,
    supplier_name: "Shashi Detective Services Pvt. Ltd",
    address:
      "No. 28, Shashikirana Complex 12 Main, 1st Block, Rajajinagar Bangalore - 560010, Karnataka, India",
    contact_person: "Mr. Ravindra Kumar",
    email_id: ["gm@shashisecurity.com"],
    phone_number: "+91-80-23133633|23134876|+91-9742222000|9880495755",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 12,
    supplier_name: "SIS Ltd.",
    address:
      "1 & 16, NG Arcade, 2nd Floor, Hesaragatta Main Road,Havanur Extension, Near Vidya School Bus Stop, Bangalore - 560022, Karnataka, India",
    contact_person: "Mr. Arockia Samy",
    email_id: ["arockia.samy@sisindia.com"],
    phone_number: "+91-9986236393",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 22,
    supplier_name: "Amanullah Khan’s Sons",
    address:
      "No.81, New Bamboo Bazaar, Parallel to N.R Road, City Market, Bangalore - 560 002, Karnataka, India",
    contact_person: "Mr. Jameel",
    email_id: ["aksabbas@gmail.com"],
    phone_number: "+91 9880244700",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 13,
    supplier_name: "Hotel Accommodation & Travel Arrangements",
    address:
      "No. 1/4, 2nd Floor, W.H. Hanumathappa Layout, 1st Cross Off. Ulsoor Road Bangalore - 560042, Karnataka, India",
    contact_person: "Mr. Debashis Dutta",
    email_id: ["hos@micehospitality.com"],
    phone_number: "+91-8884433113",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 14,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 15,
    supplier_name: "Divyashree Audio Visual",
    address:
      "# 63, 3rd A Cross, 3rd Block, Thyagarajanagar, Bangalore - 560028, Karnataka, India",
    contact_person: "Mr. Babu",
    email_id: ["divyashreeimtex@gmail.com"],
    phone_number: "+91-9845142072",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 15,
    supplier_name: "M/s. IT Vision",
    address:
      "No. 277, 2nd Cross, C.T. Bed 3rd Block, Thyagarajanagar Bangalore - 560028, Karnataka, India",
    contact_person: "Mr. Kumar",
    email_id: ["itvisionav@gmail.com"],
    phone_number: "+91-9964322319",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 16,
    supplier_name: "JRB Eventz Management Pvt. Ltd",
    address:
      "D-14/1, Basement, Okhla Industrial Area Phase-I, New Delhi - 110020, Delhi, India",
    contact_person: "Mr. Jatin Bharadwaj",
    email_id: ["jbharadwaj@jrbeventz.com"],
    phone_number: "+91-9910201927",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 17,
    supplier_name: "Crystal Hues Ltd",
    address:
      "303, Nikith Arcade, Third floor, Second Main Road, Kasturi Nagar Bangalore - 560043, Karnataka, India",
    contact_person: "Ms. Subhasree Paitandy",
    email_id: ["subhasree.paitandy@crystalhues.com"],
    phone_number: "+91-7625000102",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 18,
    supplier_name: "BVG India Limited",
    address:
      "#47, BVG HOUSE, 2nd Floor, Vasant Nagar Millers, Tank Bund Road, Bangalore - 560001 Karnataka, India",
    contact_person: "Mr. Vinay / Mr. Vikram",
    email_id: ["vinay.m@bvgindia.com", "vikram.jadhav@bvgindia.com"],
    phone_number: "+91-9686723314|7760998851",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 19,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 20,
    supplier_name: "Magic Wand Media Inc",
    address:
      "401, Omprakash Villa, B.P.S. Road Opp. Bank of India, Mulund (West) Mumbai - 400080, Maharashtra, India",
    contact_person: "Ms. Soumi Mitra",
    email_id: [
      "soumi.mitra@magicwandmedia.in",
      "murali.sundaram@magicwandmedia.in",
    ],
    phone_number: "+9- 9870401498",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 21,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 22,
    supplier_name: "Meroform India Pvt Ltd",
    address:
      "47/1, Maya industrial Area, Near Royaloak Showroom, Anchepalya, off Tumkur Road, Nagasandra Post, Bangalore - 560073, Karnataka, India",
    contact_person: "Mr. Anand Nair",
    email_id: ["anand@meroformindia.com"],
    phone_number: "+91-9873379792",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 22,
    supplier_name: "Pavilions & Interiors (I) Pvt. Ltd",
    address:
      "No. 241/N, G.A. Complex, 1st Stage, 4th Block HBR Layout, Service Road Outer Ring Road, Bangalore - 560043, Karnataka, India",
    contact_person: "Mr. Santosh",
    email_id: ["pi.bangalore@pavilionsinteriors.com"],
    phone_number: "+91-8095856888",
    deadline_date: "30/12/2023",
  },
];
export const PRODUCT_CATEGORIES = [
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.01",
      name: "All Geared Lathe/ Centre Lathes",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.02",
      name: "Bench Lathes-precision",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.03",
      name: "Capstan and Turret Lathes",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.04",
      name: "CNC Axle Journal Re-turning and Roller Burnishing Lathes",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.05",
      name: "CNC Axle Turning Lathes",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.06",
      name: "CNC Camshaft Turning Lathes",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.07",
      name: "CNC CNC Multi-spindle Bar Automatics",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.08",
      name: "CNC Crankshaft Turning Lathes",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.09",
      name: "CNC Facing Lathes",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.10",
      name: "CNC Heavy Duty and Roll Turning Lathes",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.11",
      name: "CNC Lathe, Horizontal Bed",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.12",
      name: "CNC Multi-spindle Chucking Automatics",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.13",
      name: "CNC Oval/Polygon Turning Lathes",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.14",
      name: "CNC Single Spindle Bar Automatics",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.15",
      name: "CNC Single Spindle Chucking Automatics",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.16",
      name: "CNC Turning Center",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.17",
      name: "CNC Turn-Mill Center",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.18",
      name: "CNC Twin Spindle Chuckers",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.19",
      name: "CNC Vertical Turning Lathe",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.20",
      name: "CNC Wheel Turning/ Profiling Lathes",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.21",
      name: "Copying Lathes",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.22",
      name: "Machine Tools for Engine re-building",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.23",
      name: "Multi-spindle Bar Automatics",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.24",
      name: "Multi-spindle Chucking Automatics",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.25",
      name: "Single Spindle Bar Automatics",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.26",
      name: "Single Spindle Chucking Automatics",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.27",
      name: "Sliding, Surfacing and Screw Cutting Lathes",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.28",
      name: "Watchmakers' Lathes",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A01.00",
      name: "Turning Machines",
    },
    Subcategory: {
      code: "A01.29",
      name: "Turning Machines, anyothers",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A02.00",
      name: "CNC Multi Function Machines",
    },
    Subcategory: {
      code: "A02.01",
      name: "CNC Mill-Turn Center",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A02.00",
      name: "CNC Multi Function Machines",
    },
    Subcategory: {
      code: "A02.02",
      name: "CNC Multi Function Machines, anyother",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A03.00",
      name: "Drilling Machines",
    },
    Subcategory: {
      code: "A03.01",
      name: "Bench Type Drilling Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A03.00",
      name: "Drilling Machines",
    },
    Subcategory: {
      code: "A03.02",
      name: "Column Type Drilling Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A03.00",
      name: "Drilling Machines",
    },
    Subcategory: {
      code: "A03.03",
      name: "Coordinate Drilling Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A03.00",
      name: "Drilling Machines",
    },
    Subcategory: {
      code: "A03.04",
      name: "Deep Hole Drilling and Boring Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A03.00",
      name: "Drilling Machines",
    },
    Subcategory: {
      code: "A03.05",
      name: "Gang Drilling Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A03.00",
      name: "Drilling Machines",
    },
    Subcategory: {
      code: "A03.06",
      name: "Pillar Type Drilling Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A03.00",
      name: "Drilling Machines",
    },
    Subcategory: {
      code: "A03.07",
      name: "Radial Drilling Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A03.00",
      name: "Drilling Machines",
    },
    Subcategory: {
      code: "A03.08",
      name: "Turret Head Drilling Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A03.00",
      name: "Drilling Machines",
    },
    Subcategory: {
      code: "A03.09",
      name: "Drilling Machines, anyother",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A04.00",
      name: "Boring Machines",
    },
    Subcategory: {
      code: "A04.01",
      name: "Bed Type Boring, and Milling Machines-Moving Column",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A04.00",
      name: "Boring Machines",
    },
    Subcategory: {
      code: "A04.02",
      name: "Boring and Milling Machines, Gantry Type",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A04.00",
      name: "Boring Machines",
    },
    Subcategory: {
      code: "A04.03",
      name: "Fine Boring Machines,",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A04.00",
      name: "Boring Machines",
    },
    Subcategory: {
      code: "A04.04",
      name: "Jig Boring Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A04.00",
      name: "Boring Machines",
    },
    Subcategory: {
      code: "A04.05",
      name: "Machine Tools for Engine Re-building",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A04.00",
      name: "Boring Machines",
    },
    Subcategory: {
      code: "A04.06",
      name: "Table Type Horizontal Boring and Milling Machines, Fixed Column",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A04.00",
      name: "Boring Machines",
    },
    Subcategory: {
      code: "A04.07",
      name: "Vertical Boring & Milling Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A04.00",
      name: "Boring Machines",
    },
    Subcategory: {
      code: "A04.08",
      name: "Boring and Milling Machines, anyother",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A05.00",
      name: "Milling Machines",
    },
    Subcategory: {
      code: "A05.01",
      name: "Bed Type Milling Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A05.00",
      name: "Milling Machines",
    },
    Subcategory: {
      code: "A05.02",
      name: "Copy Milling Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A05.00",
      name: "Milling Machines",
    },
    Subcategory: {
      code: "A05.03",
      name: "Crankshaft and Camshaft Milling Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A05.00",
      name: "Milling Machines",
    },
    Subcategory: {
      code: "A05.04",
      name: "Die-sinking Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A05.00",
      name: "Milling Machines",
    },
    Subcategory: {
      code: "A05.05",
      name: "Engraving Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A05.00",
      name: "Milling Machines",
    },
    Subcategory: {
      code: "A05.06",
      name: "Knee-type Milling Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A05.00",
      name: "Milling Machines",
    },
    Subcategory: {
      code: "A05.07",
      name: "Open-side Plano-milling Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A05.00",
      name: "Milling Machines",
    },
    Subcategory: {
      code: "A05.08",
      name: "Plano Milling Machines, Gantry Type",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A05.00",
      name: "Milling Machines",
    },
    Subcategory: {
      code: "A05.09",
      name: "Slot and Keyway Milling Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A05.00",
      name: "Milling Machines",
    },
    Subcategory: {
      code: "A05.10",
      name: "Tool Room Milling Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A05.00",
      name: "Milling Machines",
    },
    Subcategory: {
      code: "A05.11",
      name: "Universal Milling  Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A05.00",
      name: "Milling Machines",
    },
    Subcategory: {
      code: "A05.12",
      name: "Milling Machines, anyother",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A06.00",
      name: "Machining Centres",
    },
    Subcategory: {
      code: "A06.01",
      name: "High Speed Machining Centres",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A06.00",
      name: "Machining Centres",
    },
    Subcategory: {
      code: "A06.02",
      name: "Horizontal Machining Centres",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A06.00",
      name: "Machining Centres",
    },
    Subcategory: {
      code: "A06.03",
      name: "Machining Centres",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A06.00",
      name: "Machining Centres",
    },
    Subcategory: {
      code: "A06.04",
      name: "Portal/Gantry Type Machining Centres",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A06.00",
      name: "Machining Centres",
    },
    Subcategory: {
      code: "A06.05",
      name: "Vertical Machining Centres",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A06.00",
      name: "Machining Centres",
    },
    Subcategory: {
      code: "A06.06",
      name: "Machining Centres, anyother",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A07.00",
      name: "Flexible Manufacturing Systems",
    },
    Subcategory: {
      code: "A07.01",
      name: "Flexible Turning Cell",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A07.00",
      name: "Flexible Manufacturing Systems",
    },
    Subcategory: {
      code: "A07.02",
      name: "Flexible Machining Cell",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A07.00",
      name: "Flexible Manufacturing Systems",
    },
    Subcategory: {
      code: "A07.03",
      name: "Flexible Grinding Cell",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A07.00",
      name: "Flexible Manufacturing Systems",
    },
    Subcategory: {
      code: "A07.04",
      name: "Flexible Manufacturing Systems",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A08.00",
      name: "Special Production Machines and Unit Head",
    },
    Subcategory: {
      code: "A08.01",
      name: "Boring Units",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A08.00",
      name: "Special Production Machines and Unit Head",
    },
    Subcategory: {
      code: "A08.02",
      name: "Cylinder Boring Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A08.00",
      name: "Special Production Machines and Unit Head",
    },
    Subcategory: {
      code: "A08.03",
      name: "Drilling Units",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A08.00",
      name: "Special Production Machines and Unit Head",
    },
    Subcategory: {
      code: "A08.04",
      name: "Facing and Centering Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A08.00",
      name: "Special Production Machines and Unit Head",
    },
    Subcategory: {
      code: "A08.05",
      name: "Line Boring Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A08.00",
      name: "Special Production Machines and Unit Head",
    },
    Subcategory: {
      code: "A08.06",
      name: "Linear Indexing Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A08.00",
      name: "Special Production Machines and Unit Head",
    },
    Subcategory: {
      code: "A08.07",
      name: "Longitudinal and Circular Dividing Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A08.00",
      name: "Special Production Machines and Unit Head",
    },
    Subcategory: {
      code: "A08.08",
      name: "Milling Units",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A08.00",
      name: "Special Production Machines and Unit Head",
    },
    Subcategory: {
      code: "A08.09",
      name: "Multi-spindle Boring Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A08.00",
      name: "Special Production Machines and Unit Head",
    },
    Subcategory: {
      code: "A08.10",
      name: "Multi-spindle Drilling Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A08.00",
      name: "Special Production Machines and Unit Head",
    },
    Subcategory: {
      code: "A08.11",
      name: "Multi-spindle Tapping Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A08.00",
      name: "Special Production Machines and Unit Head",
    },
    Subcategory: {
      code: "A08.12",
      name: "Rotary Indexing Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A08.00",
      name: "Special Production Machines and Unit Head",
    },
    Subcategory: {
      code: "A08.13",
      name: "Special Units",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A08.00",
      name: "Special Production Machines and Unit Head",
    },
    Subcategory: {
      code: "A08.14",
      name: "Tapping Units",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A08.00",
      name: "Special Production Machines and Unit Head",
    },
    Subcategory: {
      code: "A08.15",
      name: "Transfer Lines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A08.00",
      name: "Special Production Machines and Unit Head",
    },
    Subcategory: {
      code: "A08.16",
      name: "Turning Units",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A08.00",
      name: "Special Production Machines and Unit Head",
    },
    Subcategory: {
      code: "A08.17",
      name: "Special Production Machines, anyother",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A09.00",
      name: "Grinding Machines",
    },
    Subcategory: {
      code: "A09.01",
      name: "Abrasive Belt Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A09.00",
      name: "Grinding Machines",
    },
    Subcategory: {
      code: "A09.02",
      name: "Centreless Grinder",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A09.00",
      name: "Grinding Machines",
    },
    Subcategory: {
      code: "A09.03",
      name: "Creep Feed Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A09.00",
      name: "Grinding Machines",
    },
    Subcategory: {
      code: "A09.04",
      name: "Cylindrical Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A09.00",
      name: "Grinding Machines",
    },
    Subcategory: {
      code: "A09.05",
      name: "High Speed Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A09.00",
      name: "Grinding Machines",
    },
    Subcategory: {
      code: "A09.06",
      name: "Horizontal Surface Grinding Machines with Rotary Table",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A09.00",
      name: "Grinding Machines",
    },
    Subcategory: {
      code: "A09.07",
      name: "Internal Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A09.00",
      name: "Grinding Machines",
    },
    Subcategory: {
      code: "A09.08",
      name: "Jig Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A09.00",
      name: "Grinding Machines",
    },
    Subcategory: {
      code: "A09.09",
      name: "Surface Grinding",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A09.00",
      name: "Grinding Machines",
    },
    Subcategory: {
      code: "A09.10",
      name: "Vertical Spindle Surface Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A09.00",
      name: "Grinding Machines",
    },
    Subcategory: {
      code: "A09.11",
      name: "Vertical Surface Grinding, Machines with Rotary Table",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A10.00",
      name: "Special Purpose Grinding Machines",
    },
    Subcategory: {
      code: "A10.01",
      name: "Axle Journal Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A10.00",
      name: "Special Purpose Grinding Machines",
    },
    Subcategory: {
      code: "A10.02",
      name: "Bearing Race Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A10.00",
      name: "Special Purpose Grinding Machines",
    },
    Subcategory: {
      code: "A10.03",
      name: "Cam Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A10.00",
      name: "Special Purpose Grinding Machines",
    },
    Subcategory: {
      code: "A10.04",
      name: "Camshaft Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A10.00",
      name: "Special Purpose Grinding Machines",
    },
    Subcategory: {
      code: "A10.05",
      name: "Centre Hole Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A10.00",
      name: "Special Purpose Grinding Machines",
    },
    Subcategory: {
      code: "A10.06",
      name: "Crankshaft Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A10.00",
      name: "Special Purpose Grinding Machines",
    },
    Subcategory: {
      code: "A10.07",
      name: "Double Disc Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A10.00",
      name: "Special Purpose Grinding Machines",
    },
    Subcategory: {
      code: "A10.08",
      name: "Grinding Machines with Flexible Shaft",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A10.00",
      name: "Special Purpose Grinding Machines",
    },
    Subcategory: {
      code: "A10.09",
      name: "Oval/Polygon Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A10.00",
      name: "Special Purpose Grinding Machines",
    },
    Subcategory: {
      code: "A10.10",
      name: "Piston Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A10.00",
      name: "Special Purpose Grinding Machines",
    },
    Subcategory: {
      code: "A10.11",
      name: "Profile Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A10.00",
      name: "Special Purpose Grinding Machines",
    },
    Subcategory: {
      code: "A10.12",
      name: "Roll Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A10.00",
      name: "Special Purpose Grinding Machines",
    },
    Subcategory: {
      code: "A10.13",
      name: "Slide-way Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A10.00",
      name: "Special Purpose Grinding Machines",
    },
    Subcategory: {
      code: "A10.14",
      name: "Spline shaft Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A10.00",
      name: "Special Purpose Grinding Machines",
    },
    Subcategory: {
      code: "A10.15",
      name: "Superfinishing Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A10.00",
      name: "Special Purpose Grinding Machines",
    },
    Subcategory: {
      code: "A10.16",
      name: "Thread Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A10.00",
      name: "Special Purpose Grinding Machines",
    },
    Subcategory: {
      code: "A10.17",
      name: "Valve Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A10.00",
      name: "Special Purpose Grinding Machines",
    },
    Subcategory: {
      code: "A10.18",
      name: "Grinding Machines for Special Purposes, anyother",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A11.00",
      name: "Tool Grinding Machines",
    },
    Subcategory: {
      code: "A11.01",
      name: "Broach Sharpening Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A11.00",
      name: "Tool Grinding Machines",
    },
    Subcategory: {
      code: "A11.02",
      name: "Carbide Tool Sharpening Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A11.00",
      name: "Tool Grinding Machines",
    },
    Subcategory: {
      code: "A11.03",
      name: "Cutter and Reamer Sharpening Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A11.00",
      name: "Tool Grinding Machines",
    },
    Subcategory: {
      code: "A11.04",
      name: "Gear Cutting Tool Sharpening Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A11.00",
      name: "Tool Grinding Machines",
    },
    Subcategory: {
      code: "A11.05",
      name: "Milling Cutter Sharpening Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A11.00",
      name: "Tool Grinding Machines",
    },
    Subcategory: {
      code: "A11.06",
      name: "Multi-axes Universal Tool Grinding Machine",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A11.00",
      name: "Tool Grinding Machines",
    },
    Subcategory: {
      code: "A11.07",
      name: "Sharpening Machines for Saw Blades",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A11.00",
      name: "Tool Grinding Machines",
    },
    Subcategory: {
      code: "A11.08",
      name: "Shear Blade and Machine Knife Sharpening Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A11.00",
      name: "Tool Grinding Machines",
    },
    Subcategory: {
      code: "A11.09",
      name: "Single Point Cutting Tool Sharpening Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A11.00",
      name: "Tool Grinding Machines",
    },
    Subcategory: {
      code: "A11.10",
      name: "Swing-frame Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A11.00",
      name: "Tool Grinding Machines",
    },
    Subcategory: {
      code: "A11.11",
      name: "Tap Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A11.00",
      name: "Tool Grinding Machines",
    },
    Subcategory: {
      code: "A11.12",
      name: "Threading Die and Chaser Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A11.00",
      name: "Tool Grinding Machines",
    },
    Subcategory: {
      code: "A11.13",
      name: "Tool Sharpening and Lapping Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A11.00",
      name: "Tool Grinding Machines",
    },
    Subcategory: {
      code: "A11.14",
      name: "Twist Drill Sharpening Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A11.00",
      name: "Tool Grinding Machines",
    },
    Subcategory: {
      code: "A11.15",
      name: "Universal Tool and Cutter Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A12.00",
      name: "Gear Cutting and Finishing Machines",
    },
    Subcategory: {
      code: "A12.01",
      name: "Bevel Gear Cutting Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A12.00",
      name: "Gear Cutting and Finishing Machines",
    },
    Subcategory: {
      code: "A12.02",
      name: "Gear Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A12.00",
      name: "Gear Cutting and Finishing Machines",
    },
    Subcategory: {
      code: "A12.03",
      name: "Gear Hobbing Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A12.00",
      name: "Gear Cutting and Finishing Machines",
    },
    Subcategory: {
      code: "A12.04",
      name: "Gear Shaping Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A12.00",
      name: "Gear Cutting and Finishing Machines",
    },
    Subcategory: {
      code: "A12.05",
      name: "Gear Shaving Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A12.00",
      name: "Gear Cutting and Finishing Machines",
    },
    Subcategory: {
      code: "A12.06",
      name: "Gear Tooth Rounding and Chamfering Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A12.00",
      name: "Gear Cutting and Finishing Machines",
    },
    Subcategory: {
      code: "A12.07",
      name: "Rack Milling Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A12.00",
      name: "Gear Cutting and Finishing Machines",
    },
    Subcategory: {
      code: "A12.08",
      name: "Gear / Spline / Thread Rolling Machines (Other than for Production of Bolts & Screws)",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A12.00",
      name: "Gear Cutting and Finishing Machines",
    },
    Subcategory: {
      code: "A12.09",
      name: "Gear Cutting Machines, anyother",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A13.00",
      name: "Planing, Shaping, Slotting and Broaching Machines",
    },
    Subcategory: {
      code: "A13.01",
      name: "Internal Broaching Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A13.00",
      name: "Planing, Shaping, Slotting and Broaching Machines",
    },
    Subcategory: {
      code: "A13.02",
      name: "Keyseating Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A13.00",
      name: "Planing, Shaping, Slotting and Broaching Machines",
    },
    Subcategory: {
      code: "A13.03",
      name: "Planing Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A13.00",
      name: "Planing, Shaping, Slotting and Broaching Machines",
    },
    Subcategory: {
      code: "A13.04",
      name: "Plate Edge Planing Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A13.00",
      name: "Planing, Shaping, Slotting and Broaching Machines",
    },
    Subcategory: {
      code: "A13.05",
      name: "Shaping Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A13.00",
      name: "Planing, Shaping, Slotting and Broaching Machines",
    },
    Subcategory: {
      code: "A13.06",
      name: "Slotting Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A13.00",
      name: "Planing, Shaping, Slotting and Broaching Machines",
    },
    Subcategory: {
      code: "A13.07",
      name: "Surface Broaching Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A13.00",
      name: "Planing, Shaping, Slotting and Broaching Machines",
    },
    Subcategory: {
      code: "A13.08",
      name: "Planing, Shaping and Broaching Machines, any other",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A14.00",
      name: "Sawing and Cutting-off Machines",
    },
    Subcategory: {
      code: "A14.01",
      name: "Abrasive Disc Cutting-off Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A14.00",
      name: "Sawing and Cutting-off Machines",
    },
    Subcategory: {
      code: "A14.02",
      name: "Band Sawing Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A14.00",
      name: "Sawing and Cutting-off Machines",
    },
    Subcategory: {
      code: "A14.03",
      name: "Circular Sawing Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A14.00",
      name: "Sawing and Cutting-off Machines",
    },
    Subcategory: {
      code: "A14.04",
      name: "Friction Sawing Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A14.00",
      name: "Sawing and Cutting-off Machines",
    },
    Subcategory: {
      code: "A14.05",
      name: "Hack Sawing Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A14.00",
      name: "Sawing and Cutting-off Machines",
    },
    Subcategory: {
      code: "A14.06",
      name: "Sawing and Filing Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A14.00",
      name: "Sawing and Cutting-off Machines",
    },
    Subcategory: {
      code: "A14.07",
      name: "Sawing and Cutting-off Machines, anyother",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A15.00",
      name: "Screw Cutting and Tapping Machines",
    },
    Subcategory: {
      code: "A15.01",
      name: "Screw Cutting and Tapping Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A16.00",
      name: "Honing, Lapping, Polishing and Deburring Machines",
    },
    Subcategory: {
      code: "A16.01",
      name: "Abrasive Flow Machine",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A16.00",
      name: "Honing, Lapping, Polishing and Deburring Machines",
    },
    Subcategory: {
      code: "A16.02",
      name: "Centreless plunge type microfinishing machine",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A16.00",
      name: "Honing, Lapping, Polishing and Deburring Machines",
    },
    Subcategory: {
      code: "A16.03",
      name: "Centreless Polishing Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A16.00",
      name: "Honing, Lapping, Polishing and Deburring Machines",
    },
    Subcategory: {
      code: "A16.04",
      name: "Cylinder Honing Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A16.00",
      name: "Honing, Lapping, Polishing and Deburring Machines",
    },
    Subcategory: {
      code: "A16.05",
      name: "Deburring Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A16.00",
      name: "Honing, Lapping, Polishing and Deburring Machines",
    },
    Subcategory: {
      code: "A16.06",
      name: "Grinding and Polishing Drums (Tumbling Barrels)",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A16.00",
      name: "Honing, Lapping, Polishing and Deburring Machines",
    },
    Subcategory: {
      code: "A16.07",
      name: "Honing Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A16.00",
      name: "Honing, Lapping, Polishing and Deburring Machines",
    },
    Subcategory: {
      code: "A16.08",
      name: "Lapping Machines, Others",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A16.00",
      name: "Honing, Lapping, Polishing and Deburring Machines",
    },
    Subcategory: {
      code: "A16.09",
      name: "Microfinishing machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A16.00",
      name: "Honing, Lapping, Polishing and Deburring Machines",
    },
    Subcategory: {
      code: "A16.10",
      name: "Polishing Machines, Abrasive Belt",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A16.00",
      name: "Honing, Lapping, Polishing and Deburring Machines",
    },
    Subcategory: {
      code: "A16.11",
      name: "Polishing Machines, Abrasive Discs",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A16.00",
      name: "Honing, Lapping, Polishing and Deburring Machines",
    },
    Subcategory: {
      code: "A16.12",
      name: "Polishing Machines, Others",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A16.00",
      name: "Honing, Lapping, Polishing and Deburring Machines",
    },
    Subcategory: {
      code: "A16.13",
      name: "Super finishing Vibrators",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A16.00",
      name: "Honing, Lapping, Polishing and Deburring Machines",
    },
    Subcategory: {
      code: "A16.14",
      name: "Surface Lapping and Cylindrical Lapping Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A17.00",
      name: "Electro Erosion Machines",
    },
    Subcategory: {
      code: "A17.01",
      name: "Die Sinking EDM (Spark Erosion)",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A17.00",
      name: "Electro Erosion Machines",
    },
    Subcategory: {
      code: "A17.02",
      name: "Electro-chemical Machine Tools",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A17.00",
      name: "Electro Erosion Machines",
    },
    Subcategory: {
      code: "A17.03",
      name: "Electrolytic Grinding Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A17.00",
      name: "Electro Erosion Machines",
    },
    Subcategory: {
      code: "A17.04",
      name: "Electrolytic Metal Working Machines, Others",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A17.00",
      name: "Electro Erosion Machines",
    },
    Subcategory: {
      code: "A17.05",
      name: "Wire EDM",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A18.00",
      name: "Robotics & Manufacturing Automation",
    },
    Subcategory: {
      code: "A18.01",
      name: "Assembling Equipment and Automatic Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A18.00",
      name: "Robotics & Manufacturing Automation",
    },
    Subcategory: {
      code: "A18.02",
      name: "Assembly Machines & Lines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A18.00",
      name: "Robotics & Manufacturing Automation",
    },
    Subcategory: {
      code: "A18.03",
      name: "Industrial Robots",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A18.00",
      name: "Robotics & Manufacturing Automation",
    },
    Subcategory: {
      code: "A18.04",
      name: "Manipulators",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A19.00",
      name: "Machines for Unconventional and other Operations",
    },
    Subcategory: {
      code: "A19.01",
      name: "Ultrasonic Machine Tools",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A20.00",
      name: "Machines For Marking",
    },
    Subcategory: {
      code: "A20.01",
      name: "Laser Marking Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A20.00",
      name: "Machines For Marking",
    },
    Subcategory: {
      code: "A20.02",
      name: "Machines For Marking, anyother",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A21.00",
      name: "Machine Tools for Educational Purposes",
    },
    Subcategory: {
      code: "A21.01",
      name: "Machine Tools for Educational Purposes",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A22.00",
      name: "Heating and Hardening Machines",
    },
    Subcategory: {
      code: "A22.01",
      name: "Induction Hardening, Induction Heat Treatment Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A22.00",
      name: "Heating and Hardening Machines",
    },
    Subcategory: {
      code: "A22.02",
      name: "Laser Heat Treatment Machines",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A22.00",
      name: "Heating and Hardening Machines",
    },
    Subcategory: {
      code: "A22.03",
      name: "Heating and Hardening Machines, anyother",
    },
  },
  {
    MainCategory: "IMTEX 2025",
    Category: {
      code: "A23.00",
      name: "Organisations",
    },
    Subcategory: {
      code: "A23.01",
      name: "Organisations",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.01",
      name: "Boring Bars",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.02",
      name: "Boring Bits",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.03",
      name: "Boring Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.04",
      name: "Broaching Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.05",
      name: "Carbide-tipped Tools & Carbide Tips",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.06",
      name: "Circular Saw Blades",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.07",
      name: "Countersinks and Core Drills",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.08",
      name: "Cutting Tools Coated",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.09",
      name: "Diamond Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.10",
      name: "Electrode Wires for WEDM",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.11",
      name: "Electrodes for EDM",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.12",
      name: "Embossing, Stamping and Marking Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.13",
      name: "Engraving Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.14",
      name: "Fine Boring Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.15",
      name: "Gear Tools (Hobbing, Shaping & Shaving)",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.16",
      name: "Gun Drills",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.17",
      name: "Hardmetal Inserts (Carbide Inserts)",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.18",
      name: "Inserted Blade Milling Cutter",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.19",
      name: "Inserted Tooth Saw Blades",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.20",
      name: "Inserts",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.21",
      name: "Knurling Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.22",
      name: "indexable Inserts of Aluminium Oxide",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.23",
      name: "Metal Band Saw Blades",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.24",
      name: "Milling Cutters",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.25",
      name: "Other Tools for Machine Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.26",
      name: "Planing & Shaping Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.27",
      name: "Plunge Cutting and Die Cutting Tools (Form Cutting Tools)",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.28",
      name: "Power Hacksaw Blades",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.29",
      name: "Reamers",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.30",
      name: "Shear Knives, Machine Knives",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.31",
      name: "Shell and Angular End Millers",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.32",
      name: "Side and Face Cutters",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.33",
      name: "Slot Millers",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.34",
      name: "Spade Drills",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.35",
      name: "Special Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.36",
      name: "Step Drills",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.37",
      name: "Surface Milling Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.38",
      name: "Taps",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.39",
      name: "Thread Rolling Dies",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.40",
      name: "Threading Dies",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.41",
      name: "Threading Tools, Cutting",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.42",
      name: "Tool Bits",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.43",
      name: "Tools for Micromachining",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.44",
      name: "Twist Drills & Centre Drills",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B01.00",
      name: "Cutting Tools",
    },
    Subcategory: {
      code: "B01.45",
      name: "Cutting Tools, anyother",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.01",
      name: "Bending dies for tubes and sections",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.02",
      name: "Bending tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.03",
      name: "Blanking and punching dies",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.04",
      name: "Car body tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.05",
      name: "Coining dies",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.06",
      name: "Composite dies",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.07",
      name: "Deep drawing dies",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.08",
      name: "Drawing dies",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.09",
      name: "Embossing, stamping and marking tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.10",
      name: "Fine blanking dies",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.11",
      name: "Forging dies",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.12",
      name: "Knurling tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.13",
      name: "Multistage dies",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.14",
      name: "Press tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.15",
      name: "Punches and dies",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.16",
      name: "Punching tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.17",
      name: "Roll forming tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.18",
      name: "Roller finishing and deep rolling tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.19",
      name: "Special tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.20",
      name: "Thread rolling tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.21",
      name: "Tools for powder metallurgy",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.22",
      name: "Tools for progressive presses",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.23",
      name: "Tools for screw manufacture",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.24",
      name: "Transfer die sets",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.25",
      name: "Notching & Nibbling Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.26",
      name: "Roller Burnishing Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.27",
      name: "Standard Parts for Press Tool Moulds & Dies",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.28",
      name: "Threading Tools, Forming",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B02.00",
      name: "Forming Tools",
    },
    Subcategory: {
      code: "B02.29",
      name: "Forming tools, any other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B03.00",
      name: "Abrasive and Polishing Tools",
    },
    Subcategory: {
      code: "B03.01",
      name: "Abrasive and Polishing Pastes",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B03.00",
      name: "Abrasive and Polishing Tools",
    },
    Subcategory: {
      code: "B03.02",
      name: "Abrasive Discs and Belts",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B03.00",
      name: "Abrasive and Polishing Tools",
    },
    Subcategory: {
      code: "B03.03",
      name: "Abrasive products with diamond",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B03.00",
      name: "Abrasive and Polishing Tools",
    },
    Subcategory: {
      code: "B03.04",
      name: "Bonded Abrasive Products",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B03.00",
      name: "Abrasive and Polishing Tools",
    },
    Subcategory: {
      code: "B03.05",
      name: "Buffing Wheels",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B03.00",
      name: "Abrasive and Polishing Tools",
    },
    Subcategory: {
      code: "B03.06",
      name: "Coated Abrasives",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B03.00",
      name: "Abrasive and Polishing Tools",
    },
    Subcategory: {
      code: "B03.07",
      name: "Deburring Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B03.00",
      name: "Abrasive and Polishing Tools",
    },
    Subcategory: {
      code: "B03.08",
      name: "Diamond Grinding Wheels",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B03.00",
      name: "Abrasive and Polishing Tools",
    },
    Subcategory: {
      code: "B03.09",
      name: "Diamond Paste",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B03.00",
      name: "Abrasive and Polishing Tools",
    },
    Subcategory: {
      code: "B03.10",
      name: "Dressing Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B03.00",
      name: "Abrasive and Polishing Tools",
    },
    Subcategory: {
      code: "B03.11",
      name: "Grinding Wheels",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B03.00",
      name: "Abrasive and Polishing Tools",
    },
    Subcategory: {
      code: "B03.12",
      name: "Honing, Lapping & Polishing Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B03.00",
      name: "Abrasive and Polishing Tools",
    },
    Subcategory: {
      code: "B03.13",
      name: "Polishing Wheels & Belts",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B03.00",
      name: "Abrasive and Polishing Tools",
    },
    Subcategory: {
      code: "B03.14",
      name: "Superfinishing Process Materials",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B03.00",
      name: "Abrasive and Polishing Tools",
    },
    Subcategory: {
      code: "B03.15",
      name: "Synthetic Diamond Powder",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B03.00",
      name: "Abrasive and Polishing Tools",
    },
    Subcategory: {
      code: "B03.16",
      name: "Trueing and Dressing Devices",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B03.00",
      name: "Abrasive and Polishing Tools",
    },
    Subcategory: {
      code: "B03.17",
      name: "Vibratory Deburring",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B03.00",
      name: "Abrasive and Polishing Tools",
    },
    Subcategory: {
      code: "B03.18",
      name: "Abrasive Tools and  Products, any other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B04.00",
      name: "Hand Held Tools",
    },
    Subcategory: {
      code: "B04.01",
      name: "Electric and Pneumatic Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B04.00",
      name: "Hand Held Tools",
    },
    Subcategory: {
      code: "B04.02",
      name: "Electric/Pneumatic Power Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B04.00",
      name: "Hand Held Tools",
    },
    Subcategory: {
      code: "B04.03",
      name: "Hand tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B04.00",
      name: "Hand Held Tools",
    },
    Subcategory: {
      code: "B04.04",
      name: "Manual deburring tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B04.00",
      name: "Hand Held Tools",
    },
    Subcategory: {
      code: "B04.05",
      name: "Hand held tools, any other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.01",
      name: "Boring & Facing Heads",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.02",
      name: "Chucking Tools with Code",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.03",
      name: "Drill Chucks",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.04",
      name: "Milling Arbors",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.05",
      name: "Multi Spindle, Angle Heads",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.06",
      name: "Multispindle Heads",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.07",
      name: "Quick Action Fixture for Press Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.08",
      name: "Shrink chucking equipment",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.09",
      name: "Sleeves",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.10",
      name: "Slotting Heads",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.11",
      name: "Speed Increasers",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.12",
      name: "Tapping",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.13",
      name: "Tool Assembly Equipment",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.14",
      name: "Tool Breakage Detection System",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.15",
      name: "Tool Changing Systems for Presses",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.16",
      name: "Tool Holders",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.17",
      name: "Tool Holders with Codes",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.18",
      name: "Tool Management System & Software",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.19",
      name: "Tool Presetting Equipment",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.20",
      name: "Tool Setting Instruments and Machines",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.21",
      name: "Tooling System, Modular",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.22",
      name: "Tool Wear Detection System",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.23",
      name: "Tooling System for Boring",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.24",
      name: "Tooling System for Drilling",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.25",
      name: "Tooling System for Grinding",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.26",
      name: "Tooling System for Milling",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.27",
      name: "Tooling System for Turning",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.28",
      name: "Turret Heads",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B05.00",
      name: "Tooling Systems",
    },
    Subcategory: {
      code: "B05.29",
      name: "Tooling Systems, any other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B06.00",
      name: "Workholding",
    },
    Subcategory: {
      code: "B06.01",
      name: "Chucking systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B06.00",
      name: "Workholding",
    },
    Subcategory: {
      code: "B06.02",
      name: "Chucks, others",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B06.00",
      name: "Workholding",
    },
    Subcategory: {
      code: "B06.03",
      name: "Clamping devices; other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B06.00",
      name: "Workholding",
    },
    Subcategory: {
      code: "B06.04",
      name: "Clamping Elements",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B06.00",
      name: "Workholding",
    },
    Subcategory: {
      code: "B06.05",
      name: "Collet Chucks",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B06.00",
      name: "Workholding",
    },
    Subcategory: {
      code: "B06.06",
      name: "Elements for Jigs & Fixtures",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B06.00",
      name: "Workholding",
    },
    Subcategory: {
      code: "B06.07",
      name: "Expanding Mandrels",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B06.00",
      name: "Workholding",
    },
    Subcategory: {
      code: "B06.08",
      name: "Fixture plates",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B06.00",
      name: "Workholding",
    },
    Subcategory: {
      code: "B06.09",
      name: "Hydraulic Clamping Device",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B06.00",
      name: "Workholding",
    },
    Subcategory: {
      code: "B06.10",
      name: "Lathe Chucks, Hand Operated",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B06.00",
      name: "Workholding",
    },
    Subcategory: {
      code: "B06.11",
      name: "Lathe Chucks, Power Operated",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B06.00",
      name: "Workholding",
    },
    Subcategory: {
      code: "B06.12",
      name: "Machine Vices",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B06.00",
      name: "Workholding",
    },
    Subcategory: {
      code: "B06.13",
      name: "Magnetic Chucks",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B06.00",
      name: "Workholding",
    },
    Subcategory: {
      code: "B06.14",
      name: "Magnetic Plates",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B06.00",
      name: "Workholding",
    },
    Subcategory: {
      code: "B06.15",
      name: "Vacuum Fixing Plate",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B06.00",
      name: "Workholding",
    },
    Subcategory: {
      code: "B06.16",
      name: "Workholding, anyother",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B07.00",
      name: "Automation for Storage & Transport",
    },
    Subcategory: {
      code: "B07.01",
      name: "Automated Guided Vehicles",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B07.00",
      name: "Automation for Storage & Transport",
    },
    Subcategory: {
      code: "B07.02",
      name: "Automated Storage and Retrieval Machines",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B07.00",
      name: "Automation for Storage & Transport",
    },
    Subcategory: {
      code: "B07.03",
      name: "Components To lnterlink Separate Production Stages",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B07.00",
      name: "Automation for Storage & Transport",
    },
    Subcategory: {
      code: "B07.04",
      name: "Conveying Equipment and Systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B07.00",
      name: "Automation for Storage & Transport",
    },
    Subcategory: {
      code: "B07.05",
      name: "Palletizing Systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B07.00",
      name: "Automation for Storage & Transport",
    },
    Subcategory: {
      code: "B07.06",
      name: "Rail Guided Vehicles",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B07.00",
      name: "Automation for Storage & Transport",
    },
    Subcategory: {
      code: "B07.07",
      name: "Automation for Storage & Transport, any other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B08.00",
      name: "Components for Robotics & Automation",
    },
    Subcategory: {
      code: "B08.01",
      name: "Changing System for Grippers",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B08.00",
      name: "Components for Robotics & Automation",
    },
    Subcategory: {
      code: "B08.02",
      name: "End Effectors and Actuators",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B08.00",
      name: "Components for Robotics & Automation",
    },
    Subcategory: {
      code: "B08.03",
      name: "Grippers and Tools for Robots",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B08.00",
      name: "Components for Robotics & Automation",
    },
    Subcategory: {
      code: "B08.04",
      name: "Gripping Tools and Automatic Fixtures",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B08.00",
      name: "Components for Robotics & Automation",
    },
    Subcategory: {
      code: "B08.05",
      name: "Loading & Feeding for Machine Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B08.00",
      name: "Components for Robotics & Automation",
    },
    Subcategory: {
      code: "B08.06",
      name: "Manipulators for Aligning and Positioning of Work pieces",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B08.00",
      name: "Components for Robotics & Automation",
    },
    Subcategory: {
      code: "B08.07",
      name: "Manipulators for Warehousing, Input and Delivery of Workpieces",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B08.00",
      name: "Components for Robotics & Automation",
    },
    Subcategory: {
      code: "B08.08",
      name: "Tool Changing Systems for Presses",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B08.00",
      name: "Components for Robotics & Automation",
    },
    Subcategory: {
      code: "B08.09",
      name: "Units for Assembly Systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.01",
      name: "Bar Feeders",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.02",
      name: "Boring Devices",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.03",
      name: "Centres, Tailstock for Lathes",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.04",
      name: "Cleaning and Degreasing Machines",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.05",
      name: "Copying Devices",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.06",
      name: "Cross Slide Tables (NC Controlled)",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.07",
      name: "Dividing Heads and Tables",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.08",
      name: "Oil Recovery Equipment  (Chip Separator)",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.09",
      name: "Package Presses for Chips",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.10",
      name: "Pneumatic and Hydraulic Accessories",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.11",
      name: "Process water treatment for superfinishing systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.12",
      name: "Spherical Turning Attachments",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.13",
      name: "Steady & Follow rests",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.14",
      name: "Steady rests self-centering",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.15",
      name: "Swarf Breaker",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.16",
      name: "Taper Turning Attachment",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.17",
      name: "Threading Devices",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.18",
      name: "Tilting Tables",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.19",
      name: "Accessories, anyother",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.20",
      name: "Air Bearing Based Linear Guiding Systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.21",
      name: "Air Heat Exchangers",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.22",
      name: "Gear Units",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.23",
      name: "Grinding Devices",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.24",
      name: "Grinding Spindles",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.25",
      name: "Grinding Wheel Trueing, Dressing and  Forming Devices",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.26",
      name: "High Frequency Spindles for Grinding",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.27",
      name: "High Pressure Coolant Systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.28",
      name: "Hydraulic Fluid Power Controls & Components",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.29",
      name: "Hydrostatic/Aerostatic Bearings",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.30",
      name: "Main Spindles for Turning, Milling & Boring and  Milling Machines",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.31",
      name: "Motor Spindle Units for Turning & Milling",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.32",
      name: "Panel Coolers and Panel AC",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.33",
      name: "Pneumatic Fluid Power Transmissions/Controls & Components",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.34",
      name: "Positioning Systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.35",
      name: "Precision Actuators",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.36",
      name: "Refrigerating Units",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.37",
      name: "Swarf Conveyor",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.38",
      name: "Through Spindle Coolant System",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.39",
      name: "Tool Magazines and Tool Changers",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.40",
      name: "Tool Turret",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.41",
      name: "Unit Heads",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.42",
      name: "Water Heat Exchangers",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.43",
      name: "Sub-system, any other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B10.00",
      name: "Mechanical Elements and Components",
    },
    Subcategory: {
      code: "B10.01",
      name: "Anti-friction Bearings including Spindle/Ball Screw",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B10.00",
      name: "Mechanical Elements and Components",
    },
    Subcategory: {
      code: "B10.02",
      name: "Anti-friction Linear Slides",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B10.00",
      name: "Mechanical Elements and Components",
    },
    Subcategory: {
      code: "B10.03",
      name: "Anti-vibration Mounts",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B10.00",
      name: "Mechanical Elements and Components",
    },
    Subcategory: {
      code: "B10.04",
      name: "Ball Screws & Planetary Roller Screws",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B10.00",
      name: "Mechanical Elements and Components",
    },
    Subcategory: {
      code: "B10.05",
      name: "Beds and Parts of Machines by Polymer Concrete & Others",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B10.00",
      name: "Mechanical Elements and Components",
    },
    Subcategory: {
      code: "B10.06",
      name: "Brake",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B10.00",
      name: "Mechanical Elements and Components",
    },
    Subcategory: {
      code: "B10.07",
      name: "Clutches",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B10.00",
      name: "Mechanical Elements and Components",
    },
    Subcategory: {
      code: "B10.08",
      name: "Couplings",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B10.00",
      name: "Mechanical Elements and Components",
    },
    Subcategory: {
      code: "B10.09",
      name: "Drive Belts",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B10.00",
      name: "Mechanical Elements and Components",
    },
    Subcategory: {
      code: "B10.10",
      name: "Hard Rock Machine Frames",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B10.00",
      name: "Mechanical Elements and Components",
    },
    Subcategory: {
      code: "B10.11",
      name: "Levelling Jacks",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B10.00",
      name: "Mechanical Elements and Components",
    },
    Subcategory: {
      code: "B10.12",
      name: "Linear Guide Rails",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B10.00",
      name: "Mechanical Elements and Components",
    },
    Subcategory: {
      code: "B10.13",
      name: "Machine Lamps",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B10.00",
      name: "Mechanical Elements and Components",
    },
    Subcategory: {
      code: "B10.14",
      name: "Slideway and Leadscrew Covers",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B10.00",
      name: "Mechanical Elements and Components",
    },
    Subcategory: {
      code: "B10.15",
      name: "Springs",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B10.00",
      name: "Mechanical Elements and Components",
    },
    Subcategory: {
      code: "B10.16",
      name: "Mechanical Elements and Components, any other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.01",
      name: "Actuators",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.02",
      name: "Air Preparation (D-F-R)",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.03",
      name: "Assemblies",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.04",
      name: "Cylinders",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.05",
      name: "Filters",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.06",
      name: "Fittings, Connections",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.07",
      name: "Gaskets, O-Rings and Seals",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.08",
      name: "High Pressure Lines and Hoses, Flexible Tubing",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.09",
      name: "Hydraulic & Pneumatic Valves",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.10",
      name: "Hydropneumatic Accumulators",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.11",
      name: "Motors",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.12",
      name: "Pneumatic Components",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.13",
      name: "Pneumatic & Oil Pneumatic Jacks",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.14",
      name: "Positining Systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.15",
      name: "Power Packs",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.16",
      name: "Pressure Reducers and Intensifiers",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.17",
      name: "Pumps",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.18",
      name: "Relieving Devices",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.19",
      name: "Rotary Unions",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.20",
      name: "Hydraulic and Pneumatic System & Elements, any other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B12.00",
      name: "Electrical & Electronic Equipment",
    },
    Subcategory: {
      code: "B12.01",
      name: "Cable Duct Systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B12.00",
      name: "Electrical & Electronic Equipment",
    },
    Subcategory: {
      code: "B12.02",
      name: "Cable Holder Chains",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B12.00",
      name: "Electrical & Electronic Equipment",
    },
    Subcategory: {
      code: "B12.03",
      name: "Control Devices and Switches",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B12.00",
      name: "Electrical & Electronic Equipment",
    },
    Subcategory: {
      code: "B12.04",
      name: "Converters",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B12.00",
      name: "Electrical & Electronic Equipment",
    },
    Subcategory: {
      code: "B12.05",
      name: "Cooling Fans",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B12.00",
      name: "Electrical & Electronic Equipment",
    },
    Subcategory: {
      code: "B12.06",
      name: "Electric Control Panels, Cubicles",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B12.00",
      name: "Electrical & Electronic Equipment",
    },
    Subcategory: {
      code: "B12.07",
      name: "Electric Motors",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B12.00",
      name: "Electrical & Electronic Equipment",
    },
    Subcategory: {
      code: "B12.08",
      name: "Electrical & Electronic Components and Assemblies",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B12.00",
      name: "Electrical & Electronic Equipment",
    },
    Subcategory: {
      code: "B12.09",
      name: "Flexible Protective Hose",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B12.00",
      name: "Electrical & Electronic Equipment",
    },
    Subcategory: {
      code: "B12.10",
      name: "Limit Switches",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B12.00",
      name: "Electrical & Electronic Equipment",
    },
    Subcategory: {
      code: "B12.11",
      name: "Machine Monitoring Systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B12.00",
      name: "Electrical & Electronic Equipment",
    },
    Subcategory: {
      code: "B12.12",
      name: "Metering and Counting Instrument & Devices",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B12.00",
      name: "Electrical & Electronic Equipment",
    },
    Subcategory: {
      code: "B12.13",
      name: "Power Electronics",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B12.00",
      name: "Electrical & Electronic Equipment",
    },
    Subcategory: {
      code: "B12.14",
      name: "Rectifiers",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B12.00",
      name: "Electrical & Electronic Equipment",
    },
    Subcategory: {
      code: "B12.15",
      name: "Sensors and Transducers",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B12.00",
      name: "Electrical & Electronic Equipment",
    },
    Subcategory: {
      code: "B12.16",
      name: "Signal Processing Systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B12.00",
      name: "Electrical & Electronic Equipment",
    },
    Subcategory: {
      code: "B12.17",
      name: "Switchgear and Control Gear",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B12.00",
      name: "Electrical & Electronic Equipment",
    },
    Subcategory: {
      code: "B12.18",
      name: "UPS & Inverters",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B12.00",
      name: "Electrical & Electronic Equipment",
    },
    Subcategory: {
      code: "B12.19",
      name: "Voltage Stabilizers & Conditioners",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B12.00",
      name: "Electrical & Electronic Equipment",
    },
    Subcategory: {
      code: "B12.20",
      name: "Electrical & Electronic Equipment for Machine Tools, any other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B13.00",
      name: "Feeding Systems",
    },
    Subcategory: {
      code: "B13.01",
      name: "Press feeding systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B13.00",
      name: "Feeding Systems",
    },
    Subcategory: {
      code: "B13.02",
      name: "Decoilers and Straighteners",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B13.00",
      name: "Feeding Systems",
    },
    Subcategory: {
      code: "B13.03",
      name: "Wire feeding systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B13.00",
      name: "Feeding Systems",
    },
    Subcategory: {
      code: "B13.04",
      name: "Automation",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B13.00",
      name: "Feeding Systems",
    },
    Subcategory: {
      code: "B13.05",
      name: "Sheet handling systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B13.00",
      name: "Feeding Systems",
    },
    Subcategory: {
      code: "B13.06",
      name: "Scrap conveyor systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B13.00",
      name: "Feeding Systems",
    },
    Subcategory: {
      code: "B13.07",
      name: "Robotic manipulators",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B13.00",
      name: "Feeding Systems",
    },
    Subcategory: {
      code: "B13.08",
      name: "Loading & Material Feeding Equipment for Machine Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B13.00",
      name: "Feeding Systems",
    },
    Subcategory: {
      code: "B13.09",
      name: "Feeding systems, any other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B14.00",
      name: "Testing Machines",
    },
    Subcategory: {
      code: "B14.01",
      name: "Balancing Machine",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B14.00",
      name: "Testing Machines",
    },
    Subcategory: {
      code: "B14.02",
      name: "Corrosion Testing Equipment",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B14.00",
      name: "Testing Machines",
    },
    Subcategory: {
      code: "B14.03",
      name: "Deep-drawing Testers",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B14.00",
      name: "Testing Machines",
    },
    Subcategory: {
      code: "B14.04",
      name: "Electronic Unbalance Measuring and Control Equipment",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B14.00",
      name: "Testing Machines",
    },
    Subcategory: {
      code: "B14.05",
      name: "Falling Weight Testing Machines",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B14.00",
      name: "Testing Machines",
    },
    Subcategory: {
      code: "B14.06",
      name: "Gear Testing Machines and Apparatus",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B14.00",
      name: "Testing Machines",
    },
    Subcategory: {
      code: "B14.07",
      name: "Hardness Testing Machine",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B14.00",
      name: "Testing Machines",
    },
    Subcategory: {
      code: "B14.08",
      name: "Machines and Apparatus for Non-destructive Metal Tests",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B14.00",
      name: "Testing Machines",
    },
    Subcategory: {
      code: "B14.09",
      name: "Metallographic Testing Equipment",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B14.00",
      name: "Testing Machines",
    },
    Subcategory: {
      code: "B14.10",
      name: "Other Testing Machines",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B14.00",
      name: "Testing Machines",
    },
    Subcategory: {
      code: "B14.11",
      name: "Tensile Testing Machine",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B14.00",
      name: "Testing Machines",
    },
    Subcategory: {
      code: "B14.12",
      name: "Testing Machines impact (Pendulum Type)",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B14.00",
      name: "Testing Machines",
    },
    Subcategory: {
      code: "B14.13",
      name: "Testing Machines, Spring Type",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B14.00",
      name: "Testing Machines",
    },
    Subcategory: {
      code: "B14.14",
      name: "Torsion Testing Machines",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B14.00",
      name: "Testing Machines",
    },
    Subcategory: {
      code: "B14.15",
      name: "Ultrasonic Testing Machines",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B14.00",
      name: "Testing Machines",
    },
    Subcategory: {
      code: "B14.16",
      name: "Universal Testing Machine",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B14.00",
      name: "Testing Machines",
    },
    Subcategory: {
      code: "B14.17",
      name: "Vibration Fatigue Testing Machines Including Tensile Testing, Compression Testing & Bending",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B14.00",
      name: "Testing Machines",
    },
    Subcategory: {
      code: "B14.18",
      name: "Testing Machines, any other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.01",
      name: "Accessories for Measuring Equipment",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.02",
      name: "Acquisition of Measured Electrical / Non-electrical Data",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.03",
      name: "Angle and inclination Measurement",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.04",
      name: "Bore Measuring Equipment",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.05",
      name: "Circularity Measurement",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.06",
      name: "Clamping Force Testers",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.07",
      name: "CMM for Measuring, Scanning and Digitising",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.08",
      name: "Computerized Measuring Machines",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.09",
      name: "CNC Coordinate Measuring Machines",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.10",
      name: "Diagnostic Systems and Software",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.11",
      name: "Digital Read Out Systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.12",
      name: "Displacement and Distance Measurement",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.13",
      name: "Dynamometer",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.14",
      name: "Electronic Gauging and Measurement Systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.15",
      name: "Equipment for Electromotive Force Measurement",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.16",
      name: "Forcev and Moment Measurement",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.17",
      name: "Gauges & Callipers",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.18",
      name: "Gear Testing Equipment",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.19",
      name: "Hob Testing Equipment",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.20",
      name: "Image Processing Systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.21",
      name: "Laser Measuring Equipment",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.22",
      name: "Layer Thickness Measurement",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.23",
      name: "Limit Signal Transmitters",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.24",
      name: "Measured Data Amplifiers",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.25",
      name: "Measuring and Marking Machines",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.26",
      name: "Measuring Instruments for Machine Geometry",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.27",
      name: "Measuring Instruments for Residual Magnetism",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.28",
      name: "Measuring Instruments, Electrical",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.29",
      name: "Measuring Instruments, Mechanical",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.30",
      name: "Measuring Instruments,Electronic",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.31",
      name: "Measuring Instruments, Optical",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.32",
      name: "Measuring Instruments,Pneumatic",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.33",
      name: "Measuring Instruments;others",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.34",
      name: "Measuring Machines, Any other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.35",
      name: "Measuring Microscopes with Digital Image Processing",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.36",
      name: "Measuring Tables",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.37",
      name: "Micrometers",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.38",
      name: "Microscopes",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.39",
      name: "Noise Measurement",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.40",
      name: "Peripherals",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.41",
      name: "Picture and Signal Pattern Recognition Sensor Systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.42",
      name: "Pressure Measurement",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.43",
      name: "Profile and Shape Measurement",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.44",
      name: "Profile Projector",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.45",
      name: "Quality Information and Display Systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.46",
      name: "Signal Processing Systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.47",
      name: "Speed Measurement",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.48",
      name: "Stereo Microscopes",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.49",
      name: "Surface Measuring Instruments & Machines",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.50",
      name: "Surface Plates and Flatness Measuring Equipment",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.51",
      name: "Systems for Tool Identification",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.52",
      name: "Temperature Measurement",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.53",
      name: "Testing Control and Management",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.54",
      name: "Thread measurement",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.55",
      name: "Transducers (Measuring Probes and Sensors)",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.56",
      name: "Vibration Meters",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.57",
      name: "Video Systems and Endoscopes",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B16.00",
      name: "Dies & Moulds",
    },
    Subcategory: {
      code: "B16.01",
      name: "Blow moulding moulds",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B16.00",
      name: "Dies & Moulds",
    },
    Subcategory: {
      code: "B16.02",
      name: "Components for dies and moulds; other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B16.00",
      name: "Dies & Moulds",
    },
    Subcategory: {
      code: "B16.03",
      name: "Die Sets",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B16.00",
      name: "Dies & Moulds",
    },
    Subcategory: {
      code: "B16.04",
      name: "Die-casting dies",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B16.00",
      name: "Dies & Moulds",
    },
    Subcategory: {
      code: "B16.05",
      name: "Drawing Dies",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B16.00",
      name: "Dies & Moulds",
    },
    Subcategory: {
      code: "B16.06",
      name: "Extrusion dies",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B16.00",
      name: "Dies & Moulds",
    },
    Subcategory: {
      code: "B16.07",
      name: "Injection moulding tools for other materials",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B16.00",
      name: "Dies & Moulds",
    },
    Subcategory: {
      code: "B16.08",
      name: "Injection moulding tools for thermoplastics",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B16.00",
      name: "Dies & Moulds",
    },
    Subcategory: {
      code: "B16.09",
      name: "Moulds for ceramics",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B16.00",
      name: "Dies & Moulds",
    },
    Subcategory: {
      code: "B16.10",
      name: "Moulds for extrusion coating",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B16.00",
      name: "Dies & Moulds",
    },
    Subcategory: {
      code: "B16.11",
      name: "Moulds for glass",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B16.00",
      name: "Dies & Moulds",
    },
    Subcategory: {
      code: "B16.12",
      name: "Moulds for rubber",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B16.00",
      name: "Dies & Moulds",
    },
    Subcategory: {
      code: "B16.13",
      name: "Multi-colour injection moulding tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B16.00",
      name: "Dies & Moulds",
    },
    Subcategory: {
      code: "B16.14",
      name: "Multi-component injection moulding tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B16.00",
      name: "Dies & Moulds",
    },
    Subcategory: {
      code: "B16.15",
      name: "Pressing dies for thermosetting plastics",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B16.00",
      name: "Dies & Moulds",
    },
    Subcategory: {
      code: "B16.16",
      name: "Sleeves",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B16.00",
      name: "Dies & Moulds",
    },
    Subcategory: {
      code: "B16.17",
      name: "Standard parts for press tools, moulds, dies",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B16.00",
      name: "Dies & Moulds",
    },
    Subcategory: {
      code: "B16.18",
      name: "Vacuum Moulds",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B16.00",
      name: "Dies & Moulds",
    },
    Subcategory: {
      code: "B16.19",
      name: "Dies and moulds, any other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B17.00",
      name: "Production Control & Networks",
    },
    Subcategory: {
      code: "B17.01",
      name: "Data Networks, also for FMS",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B17.00",
      name: "Production Control & Networks",
    },
    Subcategory: {
      code: "B17.02",
      name: "Input Devices",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B17.00",
      name: "Production Control & Networks",
    },
    Subcategory: {
      code: "B17.03",
      name: "Interactive Terminals",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B17.00",
      name: "Production Control & Networks",
    },
    Subcategory: {
      code: "B17.04",
      name: "Operational Data Acquisition",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B17.00",
      name: "Production Control & Networks",
    },
    Subcategory: {
      code: "B17.05",
      name: "Peripherals",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B17.00",
      name: "Production Control & Networks",
    },
    Subcategory: {
      code: "B17.06",
      name: "Production Control & Networks, any other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B18.00",
      name: "Controls, Drives and Systems",
    },
    Subcategory: {
      code: "B18.01",
      name: "Adaptive Controls",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B18.00",
      name: "Controls, Drives and Systems",
    },
    Subcategory: {
      code: "B18.02",
      name: "Axis Position Measuring Systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B18.00",
      name: "Controls, Drives and Systems",
    },
    Subcategory: {
      code: "B18.03",
      name: "CNC for Cutting Machines",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B18.00",
      name: "Controls, Drives and Systems",
    },
    Subcategory: {
      code: "B18.04",
      name: "CNC for Forming Machines",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B18.00",
      name: "Controls, Drives and Systems",
    },
    Subcategory: {
      code: "B18.05",
      name: "CNC for Other Machines",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B18.00",
      name: "Controls, Drives and Systems",
    },
    Subcategory: {
      code: "B18.06",
      name: "Electronic and Stored Program Controls",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B18.00",
      name: "Controls, Drives and Systems",
    },
    Subcategory: {
      code: "B18.07",
      name: "Feed Drives",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B18.00",
      name: "Controls, Drives and Systems",
    },
    Subcategory: {
      code: "B18.08",
      name: "Linear Motors",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B18.00",
      name: "Controls, Drives and Systems",
    },
    Subcategory: {
      code: "B18.09",
      name: "Programmable Controllers",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B18.00",
      name: "Controls, Drives and Systems",
    },
    Subcategory: {
      code: "B18.10",
      name: "Programming Systems for CNC/PLC",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B18.00",
      name: "Controls, Drives and Systems",
    },
    Subcategory: {
      code: "B18.11",
      name: "Robot Controllers",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B18.00",
      name: "Controls, Drives and Systems",
    },
    Subcategory: {
      code: "B18.12",
      name: "Spindle Drives",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B18.00",
      name: "Controls, Drives and Systems",
    },
    Subcategory: {
      code: "B18.13",
      name: "Control and Drive Systems, any other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B19.00",
      name: "Software",
    },
    Subcategory: {
      code: "B19.01",
      name: "ERP",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B19.00",
      name: "Software",
    },
    Subcategory: {
      code: "B19.02",
      name: "Software for Design & Development",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B19.00",
      name: "Software",
    },
    Subcategory: {
      code: "B19.03",
      name: "Software for Engineering & Methods",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B19.00",
      name: "Software",
    },
    Subcategory: {
      code: "B19.04",
      name: "Software for Production Planning & Control",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B19.00",
      name: "Software",
    },
    Subcategory: {
      code: "B19.05",
      name: "Software for Programming",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B19.00",
      name: "Software",
    },
    Subcategory: {
      code: "B19.06",
      name: "Software for Quality Control & Management",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B19.00",
      name: "Software",
    },
    Subcategory: {
      code: "B19.07",
      name: "Software for Simulation",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B19.00",
      name: "Software",
    },
    Subcategory: {
      code: "B19.08",
      name: "Software, any other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B20.00",
      name: "Services",
    },
    Subcategory: {
      code: "B20.01",
      name: "Design & Engineering",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B20.00",
      name: "Services",
    },
    Subcategory: {
      code: "B20.02",
      name: "Heat Treatment",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B20.00",
      name: "Services",
    },
    Subcategory: {
      code: "B20.03",
      name: "Surface Coating",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B20.00",
      name: "Services",
    },
    Subcategory: {
      code: "B20.04",
      name: "Machining & Manufacturing",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B20.00",
      name: "Services",
    },
    Subcategory: {
      code: "B20.05",
      name: "Erection & Maintenance, Calibration",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B20.00",
      name: "Services",
    },
    Subcategory: {
      code: "B20.06",
      name: "Tool Sharpening & Maintenance",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B20.00",
      name: "Services",
    },
    Subcategory: {
      code: "B20.07",
      name: "Research & Documentation",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B20.00",
      name: "Services",
    },
    Subcategory: {
      code: "B20.08",
      name: "Training & Education",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B21.00",
      name: "Shop Equipment",
    },
    Subcategory: {
      code: "B21.01",
      name: "Lifting & Transport Equipment",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B21.00",
      name: "Shop Equipment",
    },
    Subcategory: {
      code: "B21.02",
      name: "Small Electric Hoists for Machine Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B21.00",
      name: "Shop Equipment",
    },
    Subcategory: {
      code: "B21.03",
      name: "Storing of Workpieces and Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B21.00",
      name: "Shop Equipment",
    },
    Subcategory: {
      code: "B21.04",
      name: "Tool Cabinets",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B21.00",
      name: "Shop Equipment",
    },
    Subcategory: {
      code: "B21.05",
      name: "Work Benches, Composable Shelves",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B21.00",
      name: "Shop Equipment",
    },
    Subcategory: {
      code: "B21.06",
      name: "Work Shop Fittings",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B21.00",
      name: "Shop Equipment",
    },
    Subcategory: {
      code: "B21.07",
      name: "Shop Equipment, any other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B22.00",
      name: "Waste Disposal Equipment",
    },
    Subcategory: {
      code: "B22.01",
      name: "Chip Compacting Presses",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B22.00",
      name: "Waste Disposal Equipment",
    },
    Subcategory: {
      code: "B22.02",
      name: "Emulsion breaking",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B22.00",
      name: "Waste Disposal Equipment",
    },
    Subcategory: {
      code: "B22.03",
      name: "Equipment for Waste Disposal; others",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B22.00",
      name: "Waste Disposal Equipment",
    },
    Subcategory: {
      code: "B22.04",
      name: "Oil and oil mist vacuuming systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B22.00",
      name: "Waste Disposal Equipment",
    },
    Subcategory: {
      code: "B22.05",
      name: "Oil Recovery Equipment (Chip Separator)",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B22.00",
      name: "Waste Disposal Equipment",
    },
    Subcategory: {
      code: "B22.06",
      name: "Oil separation, oil mist separation",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B22.00",
      name: "Waste Disposal Equipment",
    },
    Subcategory: {
      code: "B22.07",
      name: "Processing of coolants and lubricants",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B22.00",
      name: "Waste Disposal Equipment",
    },
    Subcategory: {
      code: "B22.08",
      name: "Tipping containers for chip removal",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B22.00",
      name: "Waste Disposal Equipment",
    },
    Subcategory: {
      code: "B22.09",
      name: "Waste oil collecting tanks",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B22.00",
      name: "Waste Disposal Equipment",
    },
    Subcategory: {
      code: "B22.10",
      name: "Waste Disposal Equipment, any other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B23.00",
      name: "Safety & Environment",
    },
    Subcategory: {
      code: "B23.01",
      name: "Air protection systems and components",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B23.00",
      name: "Safety & Environment",
    },
    Subcategory: {
      code: "B23.02",
      name: "Dust Separating Systems, Filtering Systems, Filters",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B23.00",
      name: "Safety & Environment",
    },
    Subcategory: {
      code: "B23.03",
      name: "Exhaust systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B23.00",
      name: "Safety & Environment",
    },
    Subcategory: {
      code: "B23.04",
      name: "Fire protection safety systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B23.00",
      name: "Safety & Environment",
    },
    Subcategory: {
      code: "B23.05",
      name: "Industrial safety products",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B23.00",
      name: "Safety & Environment",
    },
    Subcategory: {
      code: "B23.06",
      name: "Industrial suction plants for cleaning up of machines",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B23.00",
      name: "Safety & Environment",
    },
    Subcategory: {
      code: "B23.07",
      name: "Noise reduction systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B23.00",
      name: "Safety & Environment",
    },
    Subcategory: {
      code: "B23.08",
      name: "Personal safety devices",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B23.00",
      name: "Safety & Environment",
    },
    Subcategory: {
      code: "B23.09",
      name: "Protection devices for robots",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B23.00",
      name: "Safety & Environment",
    },
    Subcategory: {
      code: "B23.10",
      name: "Safety devices, Splash",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B23.00",
      name: "Safety & Environment",
    },
    Subcategory: {
      code: "B23.11",
      name: "Safety Equipment for Metal Forming Machines",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B23.00",
      name: "Safety & Environment",
    },
    Subcategory: {
      code: "B23.12",
      name: "Safety equipment on the machine",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B23.00",
      name: "Safety & Environment",
    },
    Subcategory: {
      code: "B23.13",
      name: "Wastewater purification, plant and components",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B23.00",
      name: "Safety & Environment",
    },
    Subcategory: {
      code: "B23.14",
      name: "Water treatment, plant and components",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B23.00",
      name: "Safety & Environment",
    },
    Subcategory: {
      code: "B23.15",
      name: "Workshop safety devices",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B23.00",
      name: "Safety & Environment",
    },
    Subcategory: {
      code: "B23.16",
      name: "Equipment for safety and environment; any other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B24.00",
      name: "Lubrication & Coolant",
    },
    Subcategory: {
      code: "B24.01",
      name: "Coolant Filtration Systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B24.00",
      name: "Lubrication & Coolant",
    },
    Subcategory: {
      code: "B24.02",
      name: "Coolant Pumps",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B24.00",
      name: "Lubrication & Coolant",
    },
    Subcategory: {
      code: "B24.03",
      name: "Lubricants and Cutting Fluids for use on Machine Tools",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B24.00",
      name: "Lubrication & Coolant",
    },
    Subcategory: {
      code: "B24.04",
      name: "Lubrication Equipment and Devices",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B24.00",
      name: "Lubrication & Coolant",
    },
    Subcategory: {
      code: "B24.05",
      name: "Minimal lubrification technology (MQL)",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B24.00",
      name: "Lubrication & Coolant",
    },
    Subcategory: {
      code: "B24.06",
      name: "Oil Cleaning and Dispensing System",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B24.00",
      name: "Lubrication & Coolant",
    },
    Subcategory: {
      code: "B24.07",
      name: "Oil mist lubricators",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B24.00",
      name: "Lubrication & Coolant",
    },
    Subcategory: {
      code: "B24.08",
      name: "Oiling and greasing systems",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B24.00",
      name: "Lubrication & Coolant",
    },
    Subcategory: {
      code: "B24.09",
      name: "Separators for Coolants and Lubricants",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B24.00",
      name: "Lubrication & Coolant",
    },
    Subcategory: {
      code: "B24.10",
      name: "Spraying attachment for forging/die casting machines",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B24.00",
      name: "Lubrication & Coolant",
    },
    Subcategory: {
      code: "B24.11",
      name: "Lubrication and coolant; any other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B25.00",
      name: "Welding Material & Accessories",
    },
    Subcategory: {
      code: "B25.01",
      name: "Welding Fume Exhaust Units",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B25.00",
      name: "Welding Material & Accessories",
    },
    Subcategory: {
      code: "B25.02",
      name: "Welding Material",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B25.00",
      name: "Welding Material & Accessories",
    },
    Subcategory: {
      code: "B25.03",
      name: "Welding Accessories",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B25.00",
      name: "Welding Material & Accessories",
    },
    Subcategory: {
      code: "B25.04",
      name: "Other Welding Equipment",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B25.00",
      name: "Welding Material & Accessories",
    },
    Subcategory: {
      code: "B25.05",
      name: "Thermal Cutting Accessories",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B25.00",
      name: "Welding Material & Accessories",
    },
    Subcategory: {
      code: "B25.06",
      name: "Welding Torches",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B25.00",
      name: "Welding Material & Accessories",
    },
    Subcategory: {
      code: "B25.07",
      name: "Thermal Cutting Torches",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B25.00",
      name: "Welding Material & Accessories",
    },
    Subcategory: {
      code: "B25.08",
      name: "Portable Welding Machines",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B25.00",
      name: "Welding Material & Accessories",
    },
    Subcategory: {
      code: "B25.09",
      name: "Welding Positioners",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B25.00",
      name: "Welding Material & Accessories",
    },
    Subcategory: {
      code: "B25.10",
      name: "Soldering Materials",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B25.00",
      name: "Welding Material & Accessories",
    },
    Subcategory: {
      code: "B25.11",
      name: "Soldering Equipment and Accessories",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B25.00",
      name: "Welding Material & Accessories",
    },
    Subcategory: {
      code: "B25.12",
      name: "Welding Material & Accessories, any other",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B26.00",
      name: "Raw Materials",
    },
    Subcategory: {
      code: "B26.01",
      name: "Aluminium and Light Alloys",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B26.00",
      name: "Raw Materials",
    },
    Subcategory: {
      code: "B26.02",
      name: "Bar, Profile, Sections",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B26.00",
      name: "Raw Materials",
    },
    Subcategory: {
      code: "B26.03",
      name: "Blanks for Tools-hard Metal",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B26.00",
      name: "Raw Materials",
    },
    Subcategory: {
      code: "B26.04",
      name: "Blanks for Tools-HSS",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B26.00",
      name: "Raw Materials",
    },
    Subcategory: {
      code: "B26.05",
      name: "Die & Tool Steel",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B26.00",
      name: "Raw Materials",
    },
    Subcategory: {
      code: "B26.06",
      name: "Forged Parts",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B26.00",
      name: "Raw Materials",
    },
    Subcategory: {
      code: "B26.07",
      name: "Galvanized, Pre-coated",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B26.00",
      name: "Raw Materials",
    },
    Subcategory: {
      code: "B26.08",
      name: "High strength material",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B26.00",
      name: "Raw Materials",
    },
    Subcategory: {
      code: "B26.09",
      name: "High temperature material",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B26.00",
      name: "Raw Materials",
    },
    Subcategory: {
      code: "B26.10",
      name: "Industrial Adhesives",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B26.00",
      name: "Raw Materials",
    },
    Subcategory: {
      code: "B26.11",
      name: "Metal Powders",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B26.00",
      name: "Raw Materials",
    },
    Subcategory: {
      code: "B26.12",
      name: "Polymer Concrete",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B26.00",
      name: "Raw Materials",
    },
    Subcategory: {
      code: "B26.13",
      name: "Pre-Treated",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B26.00",
      name: "Raw Materials",
    },
    Subcategory: {
      code: "B26.14",
      name: "Resins for Models and Rapid Prototyping",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B26.00",
      name: "Raw Materials",
    },
    Subcategory: {
      code: "B26.15",
      name: "Shape Memory Alloys",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B26.00",
      name: "Raw Materials",
    },
    Subcategory: {
      code: "B26.16",
      name: "Sheets",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B26.00",
      name: "Raw Materials",
    },
    Subcategory: {
      code: "B26.17",
      name: "Steel and Special Alloys",
    },
  },
  {
    MainCategory: "Tooltech 2025",
    Category: {
      code: "B26.00",
      name: "Raw Materials",
    },
    Subcategory: {
      code: "B26.18",
      name: "Raw Materials, any other",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C01.00",
      name: "Industry 4.0 Hardware",
    },
    Subcategory: {
      code: "C01.01",
      name: "Communication & Networking",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C01.00",
      name: "Industry 4.0 Hardware",
    },
    Subcategory: {
      code: "C01.02",
      name: "Data Acquisition Hardware",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C01.00",
      name: "Industry 4.0 Hardware",
    },
    Subcategory: {
      code: "C01.03",
      name: "Guided Vehicles, Smart Handling & Transfer Systems",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C01.00",
      name: "Industry 4.0 Hardware",
    },
    Subcategory: {
      code: "C01.04",
      name: "Humanoid / Cooperating Robots (Cobots)",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C01.00",
      name: "Industry 4.0 Hardware",
    },
    Subcategory: {
      code: "C01.05",
      name: "Integrating Hardware",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C01.00",
      name: "Industry 4.0 Hardware",
    },
    Subcategory: {
      code: "C01.06",
      name: "IoT Devices, Sensors and Components",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C01.00",
      name: "Industry 4.0 Hardware",
    },
    Subcategory: {
      code: "C01.07",
      name: "Smart Mobile Terminals",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C01.00",
      name: "Industry 4.0 Hardware",
    },
    Subcategory: {
      code: "C01.08",
      name: "Vision Systems",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C01.00",
      name: "Industry 4.0 Hardware",
    },
    Subcategory: {
      code: "C01.09",
      name: "Industry 4.0 hardware, anyother",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C01.00",
      name: "Industry 4.0 Hardware",
    },
    Subcategory: {
      code: "C01.10",
      name: "Artificial Intelligence",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C01.00",
      name: "Industry 4.0 Hardware",
    },
    Subcategory: {
      code: "C01.11",
      name: "CAD / CAM / CAE",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C01.00",
      name: "Industry 4.0 Hardware",
    },
    Subcategory: {
      code: "C01.12",
      name: "Communication",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C01.00",
      name: "Industry 4.0 Hardware",
    },
    Subcategory: {
      code: "C01.13",
      name: "Data Analytics & Cloud Services",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C01.00",
      name: "Industry 4.0 Hardware",
    },
    Subcategory: {
      code: "C01.14",
      name: "ERP",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C01.00",
      name: "Industry 4.0 Hardware",
    },
    Subcategory: {
      code: "C01.15",
      name: "Industry 4.0 Integrated Systems",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C01.00",
      name: "Industry 4.0 Hardware",
    },
    Subcategory: {
      code: "C01.16",
      name: "Internet and Data Security",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C01.00",
      name: "Industry 4.0 Hardware",
    },
    Subcategory: {
      code: "C01.17",
      name: "Smart Factory Solutions",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C01.00",
      name: "Industry 4.0 Hardware",
    },
    Subcategory: {
      code: "C01.18",
      name: "Augmented & Virtual Reality",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C01.00",
      name: "Industry 4.0 Hardware",
    },
    Subcategory: {
      code: "C01.19",
      name: "Simulation, Digital Twins",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C01.00",
      name: "Industry 4.0 Hardware",
    },
    Subcategory: {
      code: "C01.20",
      name: "Industry 4.0 Software, anyother",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C02.00",
      name: "Additive Manufacturing",
    },
    Subcategory: {
      code: "C02.01",
      name: "Additive Manufacturing / 3D Printing Machines",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C02.00",
      name: "Additive Manufacturing",
    },
    Subcategory: {
      code: "C02.02",
      name: "Fused Deposition Modeling",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C02.00",
      name: "Additive Manufacturing",
    },
    Subcategory: {
      code: "C02.03",
      name: "Selective Laser Sintering",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C02.00",
      name: "Additive Manufacturing",
    },
    Subcategory: {
      code: "C02.04",
      name: "Technologies and Services",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C02.00",
      name: "Additive Manufacturing",
    },
    Subcategory: {
      code: "C02.05",
      name: "CAD / CAE Software in Additive Manufacturing /  3D printing",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C02.00",
      name: "Additive Manufacturing",
    },
    Subcategory: {
      code: "C02.06",
      name: "Scanning , Digitization, Metrology and Inspection equipment",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C02.00",
      name: "Additive Manufacturing",
    },
    Subcategory: {
      code: "C02.07",
      name: "Metal Moulding and Tooling",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C02.00",
      name: "Additive Manufacturing",
    },
    Subcategory: {
      code: "C02.08",
      name: "Hybrid Process",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C02.00",
      name: "Additive Manufacturing",
    },
    Subcategory: {
      code: "C02.09",
      name: "Input Materials – Powders, Granules, Filaments,Resins (Metal & Plastic)",
    },
  },
  {
    MainCategory: "Digital Manufacturing 2024",
    Category: {
      code: "C02.00",
      name: "Additive Manufacturing",
    },
    Subcategory: {
      code: "C02.10",
      name: "Additive Manufacturing, anyother",
    },
  },
];
export const PUNE_CATEGORIES = [
  {
    MainCategory: "PMTX 2024",
    Categories: [
      "Metal Cutting Machines",
      "Metal Forming & Sheet Metal Working Machines",
      "Welding Machines",
      "Machine Tool Accessories",
      "Measurement & Quality Control Equipment",
      "Hand Tools",
      "Material Handling Equipment",
      "Robotics & Automation",
      "Consumables-Cutting Tools, Grinding Wheels, Welding Electrodes",
      "Coolants, Oils, Lubricants, Packing Materials etc.",
      "Factory & Workshop Furniture",
      "Factory Storage Systems",
      "Shop Floor Maintenance",
      "Lighting Systems",
      "Cleaning Equipment",
      "Energy Efficient Devices",
      "Safety Equipment",
      "Pollution Control Equipment",
      "Low Cost Automation",
      "Enterprise Software-Low Cost Tally, ERP, Daily Work Management Software etc.",
      "Design Software - CAD/CAM/CAE",
      "Financing",
      "Skill Building - Educational Institutes, Training Institutes etc.",
      "Logistics & Supply Chain Management",
      "Industrial Development Authorities",
      "Technical Journals",
      "Digital Manufacturing",
    ],
  },
  {
    MainCategory: "FACTEQ 2024",
    Categories: [
      "Air & Water Systems",
      "HVAC",
      "Material Storage & Transportation",
      "Factory Security & Industrial Safety Systems",
      "Electrical & Lighting Systems",
      "Sustainable & Environment Solutions",
      "Maintenance Systems & Equipment",
      "Information System & Hardware",
      "Factory Furniture",
      "Clean Rooms",
      "Building & Construction",
    ],
  },
];
