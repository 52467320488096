import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  Card,
  CardContent,
  Divider,
  Paper,
  Avatar,
  Alert,
  Grid,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem,
  Switch,
  Slider,
  Rating,
  Radio,
  Checkbox,
  InputLabel,
} from "@mui/material";
import {
  DatePicker,
  TimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import parse from "html-react-parser";

import TextFieldsIcon from "@mui/icons-material/TextFields";
import SubjectIcon from "@mui/icons-material/Subject";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import StarIcon from "@mui/icons-material/Star";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import TitleIcon from "@mui/icons-material/Title";
import EditIcon from "@mui/icons-material/Edit";
import TableViewIcon from "@mui/icons-material/TableView";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import DescriptionIcon from "@mui/icons-material/Description";
import { SaveOutlined } from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import { extractDate, useQuery } from "../../../utility/utility";
import DynamicRowTable from "./widgets/DynamicRowTable";
import { UserContext } from "../../context/UserContext";
import MuPb from "../../../widgets/MuPb";
import { useNavigate } from "react-router-dom";
import SelectSupplierDetail from "./widgets/SelectSupplierDetail";
import { LoadingButton } from "@mui/lab";
import BackAndForthNav from "../../../widgets/BackAndForthNav";

const OesForms = () => {
  const query = useQuery();
  const id = query.get("id");
  const navigate = useNavigate();
  const { user, loading } = useContext(UserContext);
  const { data, isLoading, isError, error } =
    useCommonGetInit(`user/get-form-items`);
  const [selectedForm, setSelectedForm] = useState();

  useEffect(() => {
    if (id && data) {
      const form = data.find((item) => item._id === id);
      setSelectedForm(form);
    }
  }, [id, data]);

  if (loading) {
    return <MuPb />;
  }
  if (!user) {
    return <Alert severity="error">User not found!</Alert>;
  }

  const handleListItemClick = (form) => {
    navigate(`/exhibitor/oes-forms?id=${form._id}`);
  };
  function replacePlaceholders(label) {
    return label.replace(/\{(.*?)\}/g, (_, key) => {
      const plainTextKey = key.replace(/<\/?[^>]+(>|$)/g, "").trim();
      const value = user[plainTextKey]?.value;

      return value || "";
    });
  }

  const renderFinalForm = () => {
    return (
      <form>
        <Grid container width={"100%"}>
          {selectedForm?.form?.map((field) => (
            <Grid
              item
              xs={
                field?.properties?.grid ? parseInt(field.properties.grid) : 12
              }
            >
              <Box key={field.id} m={1}>
                {renderFormElementInFinalForm(field)}
              </Box>
            </Grid>
          ))}
        </Grid>
      </form>
    );
  };

  const renderFormElementInFinalForm = (field) => {
    const { type, properties } = field;
    switch (type) {
      case "TextField":
        return (
          <TextField
            type={properties.type}
            label={properties.label}
            placeholder={properties.placeholder}
            required={properties.required}
            fullWidth
            variant="outlined"
            size="small"
          />
        );
      case "PlainText":
        return (
          <Typography variant={properties.variant}>
            {properties.label}
          </Typography>
        );
      case "DynamicTable":
        return <DynamicRowTable headers={properties.headers} />;
      case "DocPDF":
        return (
          <>
            {properties.link !== "" ? (
              <a href={properties.link} target="_blank">
                <Button
                  variant="outlined"
                  startIcon={<PictureAsPdfIcon />}
                  size="small"
                >
                  {properties.label}
                </Button>
              </a>
            ) : (
              <></>
            )}
          </>
        );
      case "ParagraphDescription":
        return <>{parse(replacePlaceholders(properties.label))}</>;
      case "TextArea":
        return (
          <TextField
            label={properties.label}
            placeholder={properties.placeholder}
            required={properties.required}
            fullWidth
            variant="outlined"
            size="small"
            multiline
            rows={properties.rows}
          />
        );

      case "Checkbox":
        return (
          <FormControlLabel
            control={<Checkbox />}
            label={properties.label}
            required={properties.required}
          />
        );
      case "RadioGroup":
        return (
          <FormControl component="fieldset" required={properties.required}>
            <Typography variant="subtitle1">{properties.label}</Typography>
            <RadioGroup>
              {properties.options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      case "Select":
        return (
          <FormControl
            variant="outlined"
            fullWidth
            size="small"
            required={properties.required}
          >
            <InputLabel>{properties.label}</InputLabel>
            <Select label={properties.label}>
              {properties.options.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "Switch":
        return (
          <FormControlLabel
            control={<Switch />}
            label={properties.label}
            required={properties.required}
          />
        );
      case "DatePicker":
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={properties.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  size="small"
                  variant="outlined"
                  required={properties.required}
                />
              )}
            />
          </LocalizationProvider>
        );
      case "TimePicker":
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label={properties.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  size="small"
                  variant="outlined"
                  required={properties.required}
                />
              )}
            />
          </LocalizationProvider>
        );
      case "Slider":
        return (
          <div style={{ width: "100%", padding: "0 16px" }}>
            <Typography gutterBottom>{properties.label}</Typography>
            <Slider
              defaultValue={(properties.min + properties.max) / 2}
              min={properties.min}
              max={properties.max}
              step={properties.step}
              marks
              valueLabelDisplay="auto"
            />
          </div>
        );
      case "FileUpload":
        return (
          <TextField
            label={properties.label}
            type="file"
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            size="small"
            required={properties.required}
          />
        );
      case "Rating":
        return (
          <div>
            <Typography component="legend">{properties.label}</Typography>
            <Rating name={field.id} />
            <Typography variant="body2">Rating component goes here</Typography>
          </div>
        );
      case "Divider":
        return <Divider />;

      default:
        return null;
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      width="100%"
      pl={2}
      pr={2}
      pt={1}
      pb={1}
      gap={2}
    >
      {/* Left side: List of form names */}
      <Paper
        elevation={3}
        sx={{
          width: { xs: "100%", md: "30%" },
          pr: { md: 2 },
          mb: { xs: 2, md: 0 },
          p: 2,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Online Exhibitor Services
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <List>
          {data?.map((form) => (
            <ListItem
              button
              key={form.serial}
              selected={selectedForm?.name === form.name}
              onClick={() => handleListItemClick(form)}
              sx={{
                mb: 1,
                borderRadius: 2,
                bgcolor:
                  selectedForm === form.name
                    ? "primary.light"
                    : "background.paper",
              }}
            >
              <Avatar
                sx={{
                  mr: 2,
                  bgcolor:
                    selectedForm?.name === form.name
                      ? "primary.main"
                      : "grey.300",
                  color: "white",
                }}
              >
                {form.serial}
              </Avatar>
              <ListItemText
                primary={form.name}
                secondary={`Deadline: ${extractDate(
                  form.expiry_date,
                  "en-IN"
                )}`}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
      <Box width={{ xs: "100%", md: "70%" }}>
        <Card>
          <CardContent>
            {selectedForm ? (
              <Box>
                {renderFinalForm()}

                <Box>
                  {selectedForm?.suppliers.length > 0 && (
                    <>
                      <Divider /> <br />
                      <SelectSupplierDetail
                        supplierList={selectedForm.suppliers}
                        onChange={(newSup) => {}}
                      />
                    </>
                  )}

                  {selectedForm?.suppliers.length > 0 && (
                    <Box mt={2} display={"flex"} justifyContent={"center"}>
                      {" "}
                      <LoadingButton variant="contained">Submit</LoadingButton>
                    </Box>
                  )}

                  {selectedForm && (
                    <Box mt={2}>
                      <BackAndForthNav
                        currentIndex={selectedForm.serial - 1}
                        arrayLength={data.length}
                        onBackPress={(i) => {
                          const item = data?.find((it) => it.serial === i + 1);
                          if (item) {
                            navigate(`/exhibitor/oes-forms?id=${item._id}`);
                          }
                        }}
                        onForwardPress={(i) => {
                          const item = data?.find((it) => it.serial === i + 1);
                          if (item) {
                            navigate(`/exhibitor/oes-forms?id=${item._id}`);
                          }
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            ) : (
              <Alert severity="info">
                <Typography gutterBottom variant="h6">
                  Select form from the left menu
                </Typography>
              </Alert>
            )}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default OesForms;
