import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import RdDesignationsSelect from "../../../../../visitorregt/components/previewpage/RdDesignationsSelect";
import PhoneInput from "react-phone-number-input";
import { TITLES, getHeader } from "../../../../../utility/utility";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { isValidEmail } from "../../../../../visitorregt/utils/utils";
import axios from "axios";
import { PROFILE_EDIT_API } from "../../ProfileDetails";

const BasicInfoEdit = ({ profile, setProfile, setShow }) => {
  const [saving, setSaving] = useState(false);
  const handleInputChange = (e) => {
    setProfile((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleStateChange = (key, value) => {
    setProfile((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const save = () => {
    const checks = [
      { attr: "title", message: "Please enter Title" },
      { attr: "firstName", message: "Please enter First Name" },
      { attr: "lastName", message: "Please enter Last Name" },
      { attr: "designation", message: "Please select Designation" },
      { attr: "phone", message: "Please enter Phone Number" },
      { attr: "email", message: "Please enter Email" },
      {
        attr: "email",
        validator: isValidEmail,
        message: "Please enter valid Email",
      },
    ];

    for (let check of checks) {
      const value = profile[check.attr];
      if (
        (check.validator && !check.validator(value)) ||
        value === "" ||
        value === undefined
      ) {
        toast.error(check.message);
        return;
      }
    }
    setSaving(true);
    axios
      .post(
        PROFILE_EDIT_API,
        {
          title: profile.title,
          firstName: profile.firstName,
          lastName: profile.lastName,
          designation: profile.designation,
          phone: profile.phone,
        },
        getHeader()
      )
      .then((res) => {
        setSaving(false);
        toast.success(res.data.message);
        setShow((prev) => !prev);
      })
      .catch((error) => {
        setSaving(false);
        if (error.response) {
          // The request was made and the server responded with a status code outside the range of 2xx
          console.error("Error Data:", error.response.data);
          console.error("Error Status:", error.response.status);
          console.error("Error Headers:", error.response.headers);
          toast.error(
            `Error: ${error.response.data.message || "Something went wrong"}`
          );
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Error Request:", error.request);
          toast.error("No response from server. Please try again later.");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error", error.message);
          toast.error("An error occurred. Please try again.");
        }
      });
  };
  return (
    <div>
      <div className="visitor-form-container">
        <Typography fontSize={17}>Basic Information</Typography>
        <div className="visitor-items-c">
          <div className="visitor-items-first">
            <div style={{ display: "flex" }}>
              <FormControl
                sx={{ width: "115px", marginRight: "4px" }}
                size="small"
              >
                <InputLabel id="demo-simple-select-label">Title*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={profile.title}
                  name="title"
                  label="Title*"
                  onChange={handleInputChange}
                >
                  {TITLES.map((position, i) => (
                    <MenuItem key={i} value={position}>
                      {position}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                required
                size="small"
                name="firstName"
                value={profile.firstName}
                onChange={handleInputChange}
                label="First Name"
                variant="outlined"
                fullWidth
              />
            </div>
          </div>
          <div className="visitor-items-sec">
            <TextField
              required
              size="small"
              name="lastName"
              value={profile.lastName}
              onChange={handleInputChange}
              label="Last Name"
              variant="outlined"
              fullWidth
            />
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first" id="designation">
            <RdDesignationsSelect
              state={profile}
              handleStateChange={handleStateChange}
            />
          </div>
          <div
            className="visitor-items-sec"
            id="mobileNumber"
            style={{ position: "relative" }}
          >
            {profile.phone && profile.phone.length > 0 && (
              <label
                style={{
                  position: "absolute",
                  top: "-12px",
                  left: "56px",
                  color: "#1876d1",
                  fontSize: "14px",
                  backgroundColor: "white",
                }}
                htmlFor="phone-input"
              >
                Mobile number*
              </label>
            )}
            <PhoneInput
              defaultCountry="IN"
              placeholder="Mobile number*"
              required
              name="phone"
              value={profile.phone}
              onChange={(phone) => handleStateChange("phone", phone)}
            />
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first">
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>
                <TextField
                  required
                  size="small"
                  name="email"
                  disabled
                  value={profile.email}
                  onChange={handleInputChange}
                  label="Email"
                  variant="outlined"
                  fullWidth
                />
              </div>
            </div>
          </div>
          <div className="visitor-items-sec"></div>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <LoadingButton
          loading={saving}
          onClick={save}
          sx={{ m: 2, width: 200 }}
          variant="contained"
        >
          Save
        </LoadingButton>
      </div>
    </div>
  );
};

export default BasicInfoEdit;
