import { Container, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getHeader } from "../../../../../utility/utility";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import axios from "axios";
import { API_ENDPOINT } from "../../../../../constants/constants";
import { toastError } from "../../../../../utils2024/utils";
import CountrySelector from "../../../../../widgets/CountrySelector";
import StateSelector from "../../../../../widgets/StateSelector";
import CitySelector from "../../../../../widgets/CitySelector";

const CompanyInfoEdit = ({ user, onUpdated, show, setShow }) => {
  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    companyShortName: "",
    fascia: "",
    weAre: "",
    phone: "",
    fax: "",
    website: "",
    companyEmail: "",
    state: "",
    city: "",
    country: "",
    pin: "",
    address1: "",
    address2: "",
    address3: "",
  });
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    let companyO = {
      companyName: user.companyName.value,
      companyShortName: user.companyShortName.value,
      fascia: user.fascia.value,
      weAre: user.weAre.value,
      phone: user.phone.value,
      fax: user.fax.value,
      website: user.website.value,
      companyEmail: user.companyEmail.value,
      country: user.country?.value,
      state: user.state?.value,
      city: user.city?.value,
      pin: user.pin.value,
      address1: user.address1.value,
      address2: user.address2.value,
      address3: user.address3.value,
    };
    setCompanyInfo(companyO);
  }, [show]);
  const updateProperty = (e) => {
    setCompanyInfo({ ...companyInfo, [e.target.name]: e.target.value });
  };
  const update = async () => {
    const toUpdateObject = {
      companyName: {
        label: user.companyName.label,
        value: companyInfo.companyName,
      },
      companyShortName: {
        label: user.companyShortName.label,
        value: companyInfo.companyShortName,
      },
      fascia: {
        label: user.fascia.label,
        value: companyInfo.fascia,
      },
      weAre: {
        label: user.weAre.label,
        value: companyInfo.weAre,
      },
      phone: {
        label: user.phone.label,
        value: companyInfo.phone,
      },
      fax: {
        label: user.fax.label,
        value: companyInfo.fax,
      },
      website: {
        label: user.website.label,
        value: companyInfo.website,
      },
      companyEmail: {
        label: user.companyEmail.label,
        value: companyInfo.companyEmail,
      },
      country: {
        label: user.country.label,
        value: companyInfo.country,
      },
      state: {
        label: user.state.label,
        value: companyInfo.state,
      },
      city: {
        label: user.city.label,
        value: companyInfo.city,
      },
      pin: {
        label: user.pin.label,
        value: companyInfo.pin,
      },
      address1: {
        label: user.address1.label,
        value: companyInfo.address1,
      },
      address2: {
        label: user.address2.label,
        value: companyInfo.address2,
      },
      address3: {
        label: user.address3.label,
        value: companyInfo.address3,
      },
    };
    try {
      const { data } = await axios.post(
        `${API_ENDPOINT}user/update-user`,
        toUpdateObject,
        getHeader()
      );
      toast.success(data.message);
      setTimeout(() => {
        document.location.reload();
      }, 400);
    } catch (error) {
      toastError(error);
    } finally {
      setSaving(false);
    }
  };
  return (
    <Container>
      <Typography
        mb={2}
        fontSize={18}
        color={"#444"}
        fontWeight={600}
        variant="body1"
      >
        Update Company Information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.companyName.label}
            name="companyName"
            value={companyInfo.companyName}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.companyShortName.label}
            name="companyShortName"
            value={companyInfo.companyShortName}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.fascia.label}
            name="fascia"
            value={companyInfo.fascia}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.weAre.label}
            name="weAre"
            value={companyInfo.weAre}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.phone.label}
            name="phone"
            value={companyInfo.phone}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.fax.label}
            name="fax"
            value={companyInfo.fax}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.website.label}
            name="website"
            value={companyInfo.website}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.companyEmail.label}
            name="companyEmail"
            value={companyInfo.companyEmail}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <CountrySelector
            selectedCountryCode={""}
            selectedCountry={companyInfo.country || null}
            onChange={(newV) => {
              console.log(newV);
              setCompanyInfo({ ...companyInfo, country: newV });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          {/* <StatesSelect
            state={companyInfo}
            handleStateChange={updatePropertyNamed}
          /> */}
          <StateSelector
            countryCode={companyInfo.country?.code}
            selectedState={companyInfo.state || null}
            onChange={(newV) => {
              setCompanyInfo({ ...companyInfo, state: newV });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <CitySelector
            selectedCity={companyInfo.city || null}
            onChange={(newV) => {
              setCompanyInfo({ ...companyInfo, city: newV });
            }}
            countryCode={companyInfo.country?.code || null}
            stateCode={companyInfo.state?.code || null}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.pin.label}
            name="pin"
            value={companyInfo.pin}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.address1.label}
            name="address1"
            value={companyInfo.address1}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.address2.label}
            name="address2"
            value={companyInfo.address2}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.address3.label}
            name="address3"
            value={companyInfo.address3}
            onChange={updateProperty}
          />
        </Grid>
      </Grid>
      <LoadingButton
        loading={saving}
        onClick={update}
        sx={{
          mt: 4,
          ml: "auto",
          mr: "auto",
          display: "block",
          mb: 2,
          width: 120,
        }}
        variant="contained"
      >
        Save
      </LoadingButton>
    </Container>
  );
};

export default CompanyInfoEdit;
