import React, { useState } from "react";
import RdLoadingButton from "../../../../widgets/RdLoadingButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import BrochureItem from "../../../../widgets/BrochureItem";
import RdMyModal from "../../../../widgets/myModal/RdMyModal";
import AddBrochure from "../AddBrochure";
import HeaderLabel from "../../../../widgets/HeaderLabel";
import AddBtn from "../../../../widgets/actionBtn/addBtn";
import AddCompanyVideo from "../AddCompanyVideo";
import SimpleYesNoDialog from "../../../../widgets/SimpleYesNoDialog";
import CustomCircularP from "../../../../widgets/CustomCircularP";
import axios from "axios";
import {
  API_ENDPOINT,
  EXHIBITOR_API,
  USER,
} from "../../../../constants/constants";
import { getExhibitorHeader, getHeader } from "../../../../utility/utility";
import MuPb from "../../../../widgets/MuPb";
import useBrochures from "../hooks/useBrochures";
import { toast } from "react-toastify";

const UploadContent = ({ exhibitor, setReloadProfile, isView = false, id }) => {
  const [page, setPage] = useState(1);
  const { data, isLoading, isError, error, refetchBrochures } = useBrochures(
    page,
    id
  );
  const [showAddBrochure, setShowAddBrochure] = useState(false);
  const [showDeleteBrochure, setShowDeleteBrochure] = useState(false);
  const [currentBrochure, setCurrentBrochure] = useState();
  const [currentMode, setCurrentMode] = useState("add");

  const [loading, setLoading] = useState(false);
  if (isLoading) {
    return <MuPb />;
  }

  const onAddBrochure = () => {
    setCurrentMode("add");
    setCurrentBrochure(null);
    setShowAddBrochure(true);
  };
  const onBrochureAdded = () => {
    setShowAddBrochure(false);
    refetchBrochures();
  };
  const onEditBrochure = (item) => {
    setCurrentMode("edit");
    setCurrentBrochure(item);
    setShowAddBrochure(true);
  };
  const onDeleteBrochure = (item) => {
    setCurrentBrochure(item);
    setShowDeleteBrochure(true);
  };
  const onSelectBrochure = (item) => {
    window.open(item.pdfUrl, "_blank");
  };
  const onYesDeleteBrochure = async () => {
    setLoading(true);
    try {
      const { data } = await axios.delete(
        `${API_ENDPOINT}${USER}/delete-brochure?id=${currentBrochure._id}`,
        getHeader()
      );
      setLoading(false);
      toast.success(data.message);
      setShowDeleteBrochure(false);
      onBrochureAdded();
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "16px",
        borderRadius: "8px",
      }}
    >
      <CustomCircularP show={loading} />
      <RdMyModal
        mWidth="700px"
        showModal={showAddBrochure}
        setShowModal={setShowAddBrochure}
        modalC={
          <AddBrochure
            exhibitor={exhibitor}
            mode={currentMode}
            onAdded={onBrochureAdded}
            showAdd={showAddBrochure}
            item={currentBrochure}
          />
        }
      />
      <SimpleYesNoDialog
        title="Please Confirm!"
        message="Are you sure you want to delete this?"
        onYes={onYesDeleteBrochure}
        show={showDeleteBrochure}
        setShow={setShowDeleteBrochure}
      />
      <div style={{ display: "flex" }}>
        <HeaderLabel onEdit={null} showEdit={false} label={"Brochures"} />
        <div style={{ flex: 1 }}></div>
        {!isView && <AddBtn onClick={onAddBrochure} label={"Add Brochure"} />}
      </div>

      {data?.data?.map((item, i) => (
        <BrochureItem
          onSelect={onSelectBrochure}
          viewMode={isView}
          onEdit={onEditBrochure}
          onDelete={onDeleteBrochure}
          key={i}
          item={item}
        />
      ))}
      {data?.data?.length === 0 && (
        <div
          style={{
            margin: "16px",
            fontSize: "20px",
            color: "#888",
            fontWeight: 600,
          }}
        >
          No Brochures Found!
        </div>
      )}

      {/* <div style={{ display: "flex" }}>
        <HeaderLabel onEdit={null} showEdit={false} label={"Video"} />
        <div style={{ flex: 1 }}></div>
        {!isView && <AddBtn onClick={onAddVideo} label={"Add Video"} />}
      </div>
      <div
        style={{ marginTop: "16px", borderRadius: "12px", maxWidth: "640px" }}
        class="responsive-content"
      >
        <iframe
          width={300}
          height={120}
          src="https://www.youtube.com/embed/1V4kJktilt0"
          title="JCB#dancing#digger  bangalore karnataka"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen=""
        ></iframe>
      </div> */}
    </div>
  );
};

export default UploadContent;
