import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { toast } from "react-toastify";
import { API_ENDPOINT } from "../../../../../constants/constants";
import { getHeader } from "../../../../../utility/utility";
import ProductCategoryWidget from "../../../common/ProductCategoryWidget";
import { toastError } from "../../../../../utils2024/utils";
import { Box } from "@mui/material";

const CategoriesEdit = ({ user }) => {
  const [saving, setSaving] = useState(false);
  const [categories, setCategories] = useState([]);

  const save = async () => {
    const toUpdateObject = {
      productCategories: {
        label: user.productCategories.label,
        value: categories,
      },
    };
    setSaving(true);
    try {
      const { data } = await axios.post(
        `${API_ENDPOINT}user/update-user`,
        toUpdateObject,
        getHeader()
      );
      toast.success(data.message);
      setTimeout(() => {
        document.location.reload();
      }, 500);
    } catch (error) {
      toastError(error);
    } finally {
      setSaving(false);
    }
  };
  return (
    <Box m={2} mt={4}>
      <ProductCategoryWidget
        currentSelectedList={categories}
        onChange={(newList) => {
          setCategories(newList);
        }}
        maxSelectionAllowed={10}
      />
      <Box mt={1} display={"flex"} justifyContent={"end"}>
        <LoadingButton variant="contained" onClick={save} loading={saving}>
          Save
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default CategoriesEdit;
